import React, { Component } from 'react'

// styles
import './styles/faq-styles.css'

// bootstrap
import {
    Container,
    Row,
    Col,
    Spinner,
    Accordion,
    Card,
    Button
} from 'react-bootstrap'

import PageBanner from 'components/CommonComponents/PageBanner/PageBanner'
import { getFaqs } from 'utlis/apis/API_common'
import { stripHTML } from 'utlis/helpers/Helpers_common'


export default class Faq extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            data: []
        }
    }
    componentDidMount() {
        // CHECKING IF GLOBAL RECIPES DATA IS EMPTY
        this.setState({
            loading: true,
        })

        // GETTING INITIAL DATA
        getFaqs().then(res => {
            this.setState({
                loading: false
            })

            // adding recipes data to the redux store
            if (res.data && res.data.length) {
                this.setState({ data: res.data });
            }

        }).catch(err => {
            console.log('error occured ', err.message)
        });

    }
    render() {
        const { data, loading } = this.state;

        const filterData = [];
        let category = '';
        data.map(item => {
            if (category !== item.category) {
                filterData[item.category] = data.filter(filterData => filterData.category === item.category);
                category = item.category;
            }
            return filterData;
        });


        return (
            <>
                {/* header */}
                {/* <Header /> */}

                <section id="st-wrapper">
                    {/* page banner */}
                    <PageBanner
                        pageTitle="Guides and FAQ’s"
                        lastLinkName="Guides and FAQ’s"
                        lastLinkPath="/faq"
                    />

                    {/* disclaimer section */}
                    <div id="disclaimer-sec" className="ST_def-pad-TB st-bg-slate">
                        <Container>
                            <Row className="disclaimer-sec">

                                <Col xs={12} className="item mb-3 mb-lg-5">
                                    {
                                        loading ? (
                                            <Spinner animation="border" className="mx-auto" />
                                        ) : (
                                            <Accordion defaultActiveKey="0">
                                                {(!!filterData && Object.keys(filterData).length) ?
                                                    Object.keys(filterData).map((product, index) => (
                                                        <div key={index}>
                                                            <h1 className="st-heading heading-sm font-family-sec font-family-secondary-bold mb-3 mt-3">{product}</h1>
                                                            {filterData[product].map((faq, index) => (
                                                                <Card key={index}>
                                                                    <Card.Header>
                                                                        <Accordion.Toggle as={Button} variant="link" eventKey={String(index)} className="text-primary">
                                                                            {faq.title}
                                                                        </Accordion.Toggle>
                                                                    </Card.Header>
                                                                    <Accordion.Collapse eventKey={String(index)}>
                                                                        <Card.Body>
                                                                            
                                                            <div dangerouslySetInnerHTML={{ __html: faq.content }} />
                                                                            {/* {stripHTML()} */}
                                                                            </Card.Body>
                                                                    </Accordion.Collapse>
                                                                </Card>
                                                            ))}
                                                        </div>
                                                    )) : (
                                                        <Col xs={12} className="no-data-found-p text-center">
                                                            <p className="st-text-gray font-weight-600">No Faq added yet</p>
                                                        </Col>
                                                    )}
                                            </Accordion>
                                        )}
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    {/* footer */}
                    {/* <Footer /> */}
                </section>
            </>
        )
    }
}
