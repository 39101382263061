import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import {
  getUserAssesmentForm,
  postUserAssesmentForm,
} from "utlis/apis/API_common";

function ThirdStep(props) {
  const [stepThirdData, setStepThirdData] = useState(props.data);
  useEffect(() => {
    getUserAssesmentForm(props.commonToken)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data && data.assesmentData) {
          console.log(data.assesmentData[0]);

          setStepThirdData({
            ...data.assesmentData[0],
          });
        }
      })
      .catch((er) => console.log(er));
  }, []);

  const saveStepThird = () => {
    postUserAssesmentForm(props.commonToken, stepThirdData)
      .then((res) => {
        props.next();
        console.log(res);
      })
      .catch((er) => console.log(er));
  };

  return (
    <Table striped bordered hover responsive>
      <tbody>
        <tr>
          <td>Do You Have Any Medical / Health Issues?*</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-haveAnyHealthIssues_yes`}
              label={`Yes`}
              name="haveAnyHealthIssues"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    haveAnyHealthIssues: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.haveAnyHealthIssues === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-haveAnyHealthIssues_no`}
              label={`No`}
              name="haveAnyHealthIssues"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    haveAnyHealthIssues: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.haveAnyHealthIssues === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Any Kind of Surgery </td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-anyKindOfSurgery_yes`}
              label={`Yes`}
              name="anyKindOfSurgery"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    anyKindOfSurgery: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.anyKindOfSurgery === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`anyKindOfSurgery_no`}
              label={`No`}
              name="anyKindOfSurgery"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    anyKindOfSurgery: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.anyKindOfSurgery === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Chronic Fatigue</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-chronicFatigue_yes`}
              label={`Yes`}
              name="chronicFatigue"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    chronicFatigue: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.chronicFatigue === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`chronicFatigue_no`}
              label={`No`}
              name="chronicFatigue"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    chronicFatigue: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.chronicFatigue === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Diabetes</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-diabetes_yes`}
              label={`Yes`}
              name="diabetes"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    diabetes: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.diabetes === "yes" ? true : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`diabetes_no`}
              label={`No`}
              name="diabetes"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    diabetes: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.diabetes === "no" ? true : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Difficulty During Physical Exercise</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-travel_yes`}
              label={`Yes`}
              name="difficultyDuringPhysicalExercise"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    difficultyDuringPhysicalExercise: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.difficultyDuringPhysicalExercise === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`travel_no`}
              label={`No`}
              name="difficultyDuringPhysicalExercise"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    difficultyDuringPhysicalExercise: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.difficultyDuringPhysicalExercise === "no" ? true : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Fatty Liver</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-fattyLiver_yes`}
              label={`Yes`}
              name="fattyLiver"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    fattyLiver: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.fattyLiver === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-fattyLiver_no`}
              label={`No`}
              name="fattyLiver"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    fattyLiver: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.fattyLiver === "no" ? true : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>High Blood Pressure</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-highBloodPressure_yes`}
              label={`Yes`}
              name="highBloodPressure"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    highBloodPressure: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.highBloodPressure === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-highBloodPressure_no`}
              label={`No`}
              name="highBloodPressure"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    highBloodPressure: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.highBloodPressure === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>

        {stepThirdData.personalDETAILS?.gender !== "Male" && (
          <tr>
            {/* not in men */}
            <td>High Prolactin</td>
            <td>
              <Form.Check
                type={"radio"}
                id={`default-carryMeal_yes`}
                label={`Yes`}
                name="highProlactin"
                onChange={() =>
                  setStepThirdData({
                    ...stepThirdData,
                    medicalHistory: {
                      ...stepThirdData.medicalHistory,
                      highProlactin: "yes",
                    },
                  })
                }
                checked={
                  stepThirdData.medicalHistory?.highProlactin === "yes"
                    ? true
                    : false
                }
              />
            </td>
            <td colSpan={2}>
              <Form.Check
                type={"radio"}
                id={`default-carryMeal_no`}
                label={`No`}
                name="highProlactin"
                onChange={() =>
                  setStepThirdData({
                    ...stepThirdData,
                    medicalHistory: {
                      ...stepThirdData.medicalHistory,
                      highProlactin: "no",
                    },
                  })
                }
                checked={
                  stepThirdData.medicalHistory?.highProlactin === "no"
                    ? true
                    : false
                }
              />
            </td>
          </tr>
        )}
        <tr>
          <td>High Uric Acid / Gout </td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-highUricAcidOrGout_yes`}
              label={`Yes`}
              name="highUricAcidOrGout"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    highUricAcidOrGout: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.highUricAcidOrGout === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`highUricAcidOrGout_no`}
              label={`No`}
              name="highUricAcidOrGout"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    highUricAcidOrGout: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.highUricAcidOrGout === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>History of Breathing / Lung Aliments</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-historyOfBreathingOrLungAliments_yes`}
              label={`Yes`}
              name="historyOfBreathingOrLungAliments"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    historyOfBreathingOrLungAliments: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory
                  ?.historyOfBreathingOrLungAliments === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-historyOfBreathingOrLungAliments_no`}
              label={`No`}
              name="historyOfBreathingOrLungAliments"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    historyOfBreathingOrLungAliments: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory
                  ?.historyOfBreathingOrLungAliments === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Hyperthyroidism</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-hyperthyroidism_yes`}
              label={`Yes`}
              name="hyperthyroidism"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    hyperthyroidism: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.hyperthyroidism === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-hyperthyroidism_no`}
              label={`No`}
              name="hyperthyroidism"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    hyperthyroidism: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.hyperthyroidism === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Hypothyroidism</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-hypothyroidism_yes`}
              label={`Yes`}
              name="hypothyroidism"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    hypothyroidism: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.hypothyroidism === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-hypothyroidism_no`}
              label={`No`}
              name="hypothyroidism"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    hypothyroidism: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.hypothyroidism === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Infertility</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-infertility_yes`}
              label={`Yes`}
              name="infertility"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    infertility: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.infertility === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-infertility_no`}
              label={`No`}
              name="infertility"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    infertility: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.infertility === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Kidney Disorder</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-kidneyDisorder_yes`}
              label={`Yes`}
              name="kidneyDisorder"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    kidneyDisorder: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.kidneyDisorder === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-kidneyDisorder_no`}
              label={`No`}
              name="kidneyDisorder"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    kidneyDisorder: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.kidneyDisorder === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Low Blood Pressure</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-lowBloodPressure_yes`}
              label={`Yes`}
              name="lowBloodPressure"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    lowBloodPressure: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.lowBloodPressure === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-lowBloodPressure_no`}
              label={`No`}
              name="lowBloodPressure"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    lowBloodPressure: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.lowBloodPressure === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Orthopedic Aliment</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-orthopedicAliment_yes`}
              label={`Yes`}
              name="orthopedicAliment"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    orthopedicAliment: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.orthopedicAliment === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-orthopedicAliment_no`}
              label={`No`}
              name="orthopedicAliment"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    orthopedicAliment: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.orthopedicAliment === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Stroke</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-stroke_yes`}
              label={`Yes`}
              name="stroke"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    stroke: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.stroke === "yes" ? true : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-stroke_no`}
              label={`No`}
              name="stroke"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    stroke: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.stroke === "no" ? true : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Any Other (Please Specify)</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="Food consumed"
              onChange={(e) =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    anyOther: e.target.value,
                  },
                })
              }
              value={stepThirdData.medicalHistory?.anyOther}
            />
          </td>
        </tr>
        <tr>
          <td>Bowel / Digestive Complaints </td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-bowelOrDigestiveComplaints_yes`}
              label={`Yes`}
              name="bowelOrDigestiveComplaints"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    bowelOrDigestiveComplaints: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.bowelOrDigestiveComplaints ===
                "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-bowelOrDigestiveComplaints_no`}
              label={`No`}
              name="bowelOrDigestiveComplaints"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    bowelOrDigestiveComplaints: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.bowelOrDigestiveComplaints ===
                "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Any Proven Food Allergies</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-anyProvenFoodAllergies_yes`}
              label={`Yes`}
              name="anyProvenFoodAllergies"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    anyProvenFoodAllergies: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.anyProvenFoodAllergies === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-anyProvenFoodAllergies_no`}
              label={`No`}
              name="anyProvenFoodAllergies"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    anyProvenFoodAllergies: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.anyProvenFoodAllergies === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        {stepThirdData.personalDETAILS?.gender !== "Male" && (
          <>
            <tr>
              <td>Are Your Periods Regular?</td>
              <td>
                <Form.Check
                  type={"radio"}
                  id={`default-areYourPeriodsRegular_yes`}
                  label={`Yes`}
                  name="areYourPeriodsRegular"
                  onChange={() =>
                    setStepThirdData({
                      ...stepThirdData,
                      medicalHistory: {
                        ...stepThirdData.medicalHistory,
                        areYourPeriodsRegular: "yes",
                      },
                    })
                  }
                  checked={
                    stepThirdData.medicalHistory?.areYourPeriodsRegular ===
                    "yes"
                      ? true
                      : false
                  }
                />
              </td>
              <td colSpan={2}>
                <Form.Check
                  type={"radio"}
                  id={`default-areYourPeriodsRegular_no`}
                  label={`No`}
                  name="areYourPeriodsRegular"
                  onChange={() =>
                    setStepThirdData({
                      ...stepThirdData,
                      medicalHistory: {
                        ...stepThirdData.medicalHistory,
                        areYourPeriodsRegular: "no",
                      },
                    })
                  }
                  checked={
                    stepThirdData.medicalHistory?.areYourPeriodsRegular === "no"
                      ? true
                      : false
                  }
                />
              </td>
            </tr>

            <tr>
              <td>Do You PMS?</td>
              <td>
                <Form.Check
                  type={"radio"}
                  id={`default-doYouPMS_yes`}
                  label={`Yes`}
                  name="doYouPMS"
                  onChange={() =>
                    setStepThirdData({
                      ...stepThirdData,
                      medicalHistory: {
                        ...stepThirdData.medicalHistory,
                        doYouPMS: "yes",
                      },
                    })
                  }
                  checked={
                    stepThirdData.medicalHistory?.doYouPMS === "yes"
                      ? true
                      : false
                  }
                />
              </td>

              <td colSpan={2}>
                <Form.Check
                  type={"radio"}
                  id={`default-doYouPMS_no`}
                  label={`No`}
                  name="doYouPMS"
                  onChange={() =>
                    setStepThirdData({
                      ...stepThirdData,
                      medicalHistory: {
                        ...stepThirdData.medicalHistory,
                        doYouPMS: "no",
                      },
                    })
                  }
                  checked={
                    stepThirdData.medicalHistory?.doYouPMS === "no"
                      ? true
                      : false
                  }
                />
              </td>
            </tr>

            <tr>
              <td>Last Menstrual Periods (LMP)</td>
              <td colSpan={2}>
                <Form.Control
                  type={"text"}
                  placeholder="LMP"
                  name="lastMenstrualPeriods"
                  onChange={(e) =>
                    setStepThirdData({
                      ...stepThirdData,
                      medicalHistory: {
                        ...stepThirdData.medicalHistory,
                        lastMenstrualPeriods: e.target.value,
                      },
                    })
                  }
                  value={stepThirdData.medicalHistory?.lastMenstrualPeriods}
                />
              </td>
              
            </tr>
            <tr>
              <td>ARE YOU IN MENOPAUSE?</td>
              <td>
                <Form.Check
                  type={"radio"}
                  id={`default-areYouInMenopause_yes`}
                  label={`Yes`}
                  name="areYouInMenopause"
                  onChange={() =>
                    setStepThirdData({
                      ...stepThirdData,
                      medicalHistory: {
                        ...stepThirdData.medicalHistory,
                        areYouInMenopause: "yes",
                      },
                    })
                  }
                  checked={
                    stepThirdData.medicalHistory?.areYouInMenopause === "yes"
                      ? true
                      : false
                  }
                />
              </td>
              <td colSpan={2}>
                <Form.Check
                  type={"radio"}
                  id={`default-areYouInMenopause_no`}
                  label={`No`}
                  name="areYouInMenopause"
                  onChange={() =>
                    setStepThirdData({
                      ...stepThirdData,
                      medicalHistory: {
                        ...stepThirdData.medicalHistory,
                        areYouInMenopause: "no",
                      },
                    })
                  }
                  checked={
                    stepThirdData.medicalHistory?.areYouInMenopause === "no"
                      ? true
                      : false
                  }
                />
              </td>
            </tr>
          </>
        )}
        <tr>
          <td>
            Any Test OR Reports Done by You Recently (Last 6 Months)? Test Name
          </td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="Test name"
              onChange={(e) =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    anyTestOrReportsDoneByYouRecently: e.target.value,
                  },
                })
              }
              value={
                stepThirdData.medicalHistory?.anyTestOrReportsDoneByYouRecently
              }
            />
          </td>
        </tr>
        <tr>
          <td>Are You on Medications?</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-areYouOnMedications_yes`}
              label={`Yes`}
              name="areYouOnMedications"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    areYouOnMedications: "yes",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.areYouOnMedications === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-areYouOnMedications_no`}
              label={`No`}
              name="areYouOnMedications"
              onChange={() =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    areYouOnMedications: "no",
                  },
                })
              }
              checked={
                stepThirdData.medicalHistory?.areYouOnMedications === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        {stepThirdData.personalDETAILS?.gender !== "Male" &&
          stepThirdData.personalDETAILS?.maritalStatus !== "Unmarried" && (
            <>
              <tr>
                <td>Pregnancy History</td>
                <td colSpan={3}>
                  <select
                    name="pregnancyHistory"
                    className="form-control"
                    onChange={(e) =>
                      setStepThirdData({
                        ...stepThirdData,
                        medicalHistory: {
                          ...stepThirdData.medicalHistory,
                          pregnancyHistory: e.target.value,
                        },
                      })
                    }
                  >
                    <option>Open this select menu</option>
                    <option value="breakfast">High Blood Pressure </option>
                    <option value="morning_snacks">Gestation</option>
                    <option value="lunch">Diabetes</option>
                    <option value="evening_snack">Thyroid Problems</option>
                    <option value="dinner">Anemia</option>
                    <option value="any_other">Any Other Specify</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Number of Children</td>
                <td>
                  <Form.Check
                    type={"radio"}
                    id={`default-numberOfChildren_yes`}
                    label={`Yes`}
                    name="numberOfChildren"
                    onChange={() =>
                      setStepThirdData({
                        ...stepThirdData,
                        medicalHistory: {
                          ...stepThirdData.medicalHistory,
                          numberOfChildren: "yes",
                        },
                      })
                    }
                    checked={
                      stepThirdData.medicalHistory?.numberOfChildren === "yes"
                        ? true
                        : false
                    }
                  />
                </td>
                <td colSpan={2}>
                  <Form.Check
                    type={"radio"}
                    id={`default-numberOfChildren_no`}
                    label={`No`}
                    name="numberOfChildren"
                    onChange={() =>
                      setStepThirdData({
                        ...stepThirdData,
                        medicalHistory: {
                          ...stepThirdData.medicalHistory,
                          numberOfChildren: "no",
                        },
                      })
                    }
                    checked={
                      stepThirdData.medicalHistory?.numberOfChildren === "no"
                        ? true
                        : false
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Any Post Pregnancy Complications?*</td>
                <td colSpan={3}>
                  <select
                    name="anyPostPregnancyComplications"
                    className="form-control"
                    onChange={(e) =>
                      setStepThirdData({
                        ...stepThirdData,
                        medicalHistory: {
                          ...stepThirdData.medicalHistory,
                          anyPostPregnancyComplications: e.target.value,
                        },
                      })
                    }
                  >
                    <option>Open this select menu</option>
                    <option value="highbloodpressure">
                      High Blood Pressure{" "}
                    </option>
                    <option value="gestation">Gestation</option>
                    <option value="diabetes">Diabetes</option>
                    <option value="thyroidprob">Thyroid Problems</option>
                    <option value="anemia">Anemia</option>
                    <option value="any_other">Any Other Specify</option>
                  </select>
                </td>
              </tr>
              {console.log(
                stepThirdData.medicalHistory?.anyPostPregnancyComplications
              )}

              {stepThirdData.medicalHistory?.anyPostPregnancyComplications ==
                "any_other" && (
                <tr>
                  <td>Any Other Specify *</td>
                  <td colSpan={3}>
                    <Form.Control
                      plaintext
                      placeholder="Any other Specify"
                      onChange={(e) =>
                        setStepThirdData({
                          ...stepThirdData,
                          medicalHistory: {
                            ...stepThirdData.medicalHistory,
                            anyPostPregnancyComplicationsAnyOther:
                              e.target.value,
                          },
                        })
                      }
                      value={
                        stepThirdData.medicalHistory
                          ?.anyPostPregnancyComplicationsAnyOther
                      }
                    />
                  </td>
                </tr>
              )}
            </>
          )}
        <tr>
          <td>
            Are You Taking Any Supplements or Vitamins? If Yes, Please Fill in
            The Name and Type of Supplement That You Are Taking, And for Which
            Particular Condition. Kindly Elaborate on The Comment Section If
            Deemed Necessary.
          </td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="Supplement name"
              onChange={(e) =>
                setStepThirdData({
                  ...stepThirdData,
                  medicalHistory: {
                    ...stepThirdData.medicalHistory,
                    AreYouTakingAnySupplementsOrVitamins: e.target.value,
                  },
                })
              }
              value={
                stepThirdData.medicalHistory
                  ?.AreYouTakingAnySupplementsOrVitamins
              }
            />
          </td>
        </tr>
        <tr>
          <td colSpan={4} className="text-right">
            <Button onClick={() => props.prev()} className='mr-5'>Previous</Button>
            <Button onClick={saveStepThird}>Next</Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
export default ThirdStep;
