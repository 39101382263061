import React, { Component } from 'react'

export default class AssesmentFormStep1 extends Component {
    constructor(props) {
        super(props)

     
    }
   
    
    dropdowNumbers = (n, name = '', start = 1) => {
        let option = [];
        for (let i = start; i <= n; i++) {
            option.push(<option value={i} key={i}>{i + ' ' + name}</option>);
        }
        // console.log(option);
        return option;
    }
    
    render() {
       
        return (
            <>
                <table className="table table-borderless">
                    <tbody>
                                            <tr>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <label >Name*</label>
                                                        <input type="text" className="form-control" id="name" name="name" value={this.props.fmdata.name} onChange={ev => this.props.fieldChangeHandler(ev)} required />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <label>Email*</label>
                                                        <input type="email" className="form-control" id="email" name="email" value={this.props.fmdata.email} onChange={ev => this.props.fieldChangeHandler(ev)} required />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <label >Address</label>
                                                        <textarea className="form-control" id="address" placeholder="Address " value={this.props.fmdata.    address} onChange={ev => this.props.fieldChangeHandler(ev)} name="address" ></textarea>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-group col-sm-12">
                                                        <label >City</label>
                                                        <input type="text" className="form-control" id="city" name="city" value={this.props.fmdata.city} onChange={ev => this.props.fieldChangeHandler(ev)} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <label >State</label>
                                                        <input type="text" className="form-control" id="state" name="state" value={this.props.fmdata.state} onChange={ev => this.props.fieldChangeHandler(ev)} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-group col-sm-12">
                                                        <label >Country</label>
                                                        <input type="text" className="form-control" id="country" name="country" value={this.props.fmdata.country} onChange={ev => this.props.fieldChangeHandler(ev)} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <label>Mobile Number*</label>
                                                        <input type="text" className="form-control" id="number" name="number" value={this.props.fmdata.number} onChange={ev => this.props.fieldChangeHandler(ev)} required />
                                                    </div>
                                                </td>
                                                <td>
                                                    
                                                        
                                                        <div className="form-group col-md-12">
                                                            <label >Date Of Birth*</label>
                                                            <input type="date" className="form-control" id="mn" name="dob" required value={this.props.fmdata.dob} placeholder="mm/dd/yyyy" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                                        </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    
                                                        
                                                        <div className="form-group col-md-12">
                                                            <label >Gender*</label>
                                                            <select className="custom-select" name="gender" required 
                                                            defaultValue={this.props.fmdata.gender}
                                                            onChange={ev => this.props.fieldChangeHandler(ev)} >
                                                                <option disabled>select option</option>
                                                                <option value="male">Male</option>
                                                                <option value="female">Female</option>
                                                            </select>
                                                        </div>
                                                    
                                                </td>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <label >Age*</label>
                                                        <select className="custom-select" name="age"
                                                        defaultValue={this.props.fmdata.age}
                                                        required onChange={ev => this.props.fieldChangeHandler(ev)} >
                                                                <option  disabled>select option</option>
                                                                {this.dropdowNumbers(100, "(years)")}
                                                        </select>                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <label >Weight*</label>
                                                        <div className="row">
                                                            <div className="form-group col-md-6">
                                                            <label >Kg</label>
                                                            <select className="custom-select" name="wgt_kg" 
                                                            defaultValue={this.props.fmdata.wgt_kg}
                                                            required onChange={ev => this.props.fieldChangeHandler(ev)} >
                                                                <option  disabled>select option</option>
                                                                {this.dropdowNumbers(200, "(kg)")}
                                                            </select>                                                        
                                                                {/* <input type="text" className="form-control" id="weight" name="wgt_kg" onChange={ev => this.props.fieldChangeHandler(ev)} required placeholder="kg" /> */}
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label >gm</label>
                                                                <select className="custom-select" name="wgt_gm" 
                                                                defaultValue={this.props.fmdata.wgt_gm}
                                                                required onChange={ev => this.props.fieldChangeHandler(ev)} >
                                                                    <option  disabled>select option</option>
                                                                    {this.dropdowNumbers(1000, "(gm)")}
                                                                </select>                                                        
                                                                {/* <input type="text" className="form-control" id="weight" name="wgt_gm" onChange={ev => this.props.fieldChangeHandler(ev)} required placeholder="gm" /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <label >Height*</label>
                                                        <div className="row">
                                                            <div className="form-group col-md-6">
                                                                <label >Ft</label>
                                                                <select className="custom-select" name="hght_ft" 
                                                                defaultValue={this.props.fmdata.hght_ft}
                                                                required onChange={ev => this.props.fieldChangeHandler(ev)} >
                                                                    <option disabled>select option</option>
                                                                    {this.dropdowNumbers(10, "(ft)")}
                                                                </select>    
                                                                {/* <input type="text" className="form-control" id="height_ft" placeholder="ft" name="hght_ft" onChange={ev => this.props.fieldChangeHandler(ev)} required /> */}
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label >in</label>
                                                                <select className="custom-select" name="hght_inch"
                                                                defaultValue={this.props.fmdata.hght_inch}
                                                                required onChange={ev => this.props.fieldChangeHandler(ev)} >
                                                                    <option  disabled >select option</option>
                                                                    {this.dropdowNumbers(12, "(inch)")}
                                                                </select>    
                                                                {/* <input type="text" className="form-control" id="height" placeholder="in" name="hght_inch" onChange={ev => this.props.fieldChangeHandler(ev)} required /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <label >What’s Your Target Weight?</label>
                                                        <div className="row">
                                                            <div className="form-group col-md-6">
                                                                <select className="custom-select" name="trgt_wgt_kg" 
                                                                defaultValue={this.props.fmdata.trgt_wgt_kg}
                                                                required onChange={ev => this.props.fieldChangeHandler(ev)} >
                                                                    <option  disabled>select option</option>
                                                                    {this.dropdowNumbers(100, "(kg)")}
                                                                </select>  
                                                                {/* <input type="text" className="form-control" id="trgt_wgt_kg" name="trgt_wgt_kg" onChange={ev => this.props.fieldChangeHandler(ev)} placeholder="kg" /> */}
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <select className="custom-select" name="trgt_wgt_gm"
                                                                defaultValue={this.props.fmdata.trgt_wgt_gm}
                                                                required onChange={ev => this.props.fieldChangeHandler(ev)} >
                                                                    <option  disabled>select option</option>
                                                                    {this.dropdowNumbers(999, "(gm)")}
                                                                </select>  
                                                                {/* <input type="text" className="form-control" id="trgt_wgt_gm" name="trgt_wgt_gm" onChange={ev => this.props.fieldChangeHandler(ev)} placeholder="gm" /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <label >Any Other Goal That You Have In Mind?*</label>
                                                        <input type="text" className="form-control" id="other_goal" name="other_goal" value={this.props.fmdata.other_goal} onChange={ev => this.props.fieldChangeHandler(ev)} required />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <label >Caste*</label>
                                                        <input type="text" className="form-control" id="caste" value={this.props.fmdata.caste} name="caste" onChange={ev => this.props.fieldChangeHandler(ev)} required />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <label >Marital Status*</label>
                                                        <select className="custom-select" name="marit_status" 
                                                        defaultValue={this.props.fmdata.marit_status}
                                                        required onChange={ev => this.props.fieldChangeHandler(ev)}>
                                                            <option disabled>select option</option>
                                                            <option value="married">Married</option>
                                                            <option value="unmarried">Unmarried</option>
                                                            <option value="separated">Separated</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>    
                                                    <div className="form-group col-md-12">
                                                        <label >Type Of Family</label>
                                                        <select className="custom-select" name="type_family"
                                                        defaultValue={this.props.fmdata.type_family}
                                                        onChange={ev => this.props.fieldChangeHandler(ev)}>
                                                            <option disabled>select option</option>
                                                            <option value="join_fm">Joint Family</option>
                                                            <option value="nucl_fm">Nuclear Family </option>
                                                            <option value="py_fm">Paying Guest/ Alone</option>
                                                        </select>
                                                    </div>
                                                    
                                                </td>
                                                <td>
                           
                                                <div className="btn-sec d-flex justify-content-end border-top st-border-default pt-3 pt-lg-4 mt-3 mt-lg-5">
                                                    <button
                                                        className="btn st-btn st-btn-primary text-uppercase font-family-secondary-bold font-size-14"
                                                        disabled={  (this.props.fmdata.name == ' ' ||
                                                        this.props.fmdata.email == ' ' ||
                                                        this.props.fmdata.number == ' ' ||
                                                        this.props.fmdata.dob == ' ' ||
                                                        this.props.fmdata.gender == ' ' ||
                                                        this.props.fmdata.age == ' ' ||
                                                        this.props.fmdata.wgt_kg == ' ' ||
                                                        this.props.fmdata.wgt_gm == ' ' ||
                                                        this.props.fmdata.hght_ft == ' ' ||
                                                        this.props.fmdata.hght_inch == ' ' ||
                                                        this.props.fmdata.other_goal == ' ' ||
                                                        this.props.fmdata.caste == ' ' ||
                                                        this.props.fmdata.marit_status == ' ') }
                                                        onClick={ev => {
                                                            // opening step 2
                                                            this.props.openStep2Tab(ev)
                                                        }}
                                                    >next</button>
                                            </div>
                                                </td>
                                            </tr>
                                    </tbody>
                            </table>
            </>
        )
    }
}
