// auth
export const SAVE_COMMONTOKEN = 'SAVE_COMMONTOKEN'
export const REMOVE_COMMONTOKEN = 'REMOVE_COMMONTOKEN'
export const SAVE_CURRENT_USER = 'SAVE_CURRENT_USER'
export const REMOVE_CURRENT_USER = 'REMOVE_CURRENT_USER'
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER'
export const EMPTY_AUTH = 'EMPTY_AUTH'

// recipes
export const ADD_RECIPES = 'ADD_RECIPES'
export const UPDATE_RECIPES = 'UPDATE_RECIPES'
export const EMPTY_RECIPES = 'EMPTY_RECIPES'

// success stories
export const ADD_SUCCESS_STORIES = 'ADD_SUCCESS_STORIES'
export const UPDATE_SUCCESS_STORIES = 'UPDATE_SUCCESS_STORIES'
export const EMPTY_SUCCESS_STORIES = 'EMPTY_SUCCESS_STORIES'

// blogs
export const ADD_BLOGS = 'ADD_BLOGS'
export const UPDATE_BLOGS = 'UPDATE_BLOGS'
export const EMPTY_BLOGS = 'EMPTY_BLOGS'