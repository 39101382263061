import React from 'react'

// styles
import './styles/aarogyam-styles.css'

// bootstrap
import {
    Container,
} from 'react-bootstrap'

// router
import { Helmet } from 'react-helmet'

// bg image
import bgImg from 'assets/images/page-full-bg.jpg'
import img_bottles from 'assets/images/full-page/img-bottles.jpg'
import img_burger from 'assets/images/full-page/img-burger.jpg'
import img_heart from 'assets/images/full-page/img-heart.jpg'
import img_restaurant from 'assets/images/full-page/img-restaurant.jpg'
import img_ring from 'assets/images/full-page/img-ring.jpg'
import img_travel from 'assets/images/full-page/img-travel.jpg'

export default function Aarogyam2() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>NUTRIVALVE WELLNESS | Aarogyam</title>
                <link rel="canonical" href="" />
                <meta name="description" content="Aarogyam" />
            </Helmet>

            {/* main section */}
            <section id="st-full-page" style={{ backgroundImage: `url('${bgImg}')` }}>
                <Container>
                    <div className="st-full-page">
                        {/* heading wrapper */}
                        <div className="heading-wrapper text-center">
                            <p className="head">Aarogyam</p>
                            <p className="desc">Simple, easy to follow and convenient weight loss from home <br /> Anywhere in the world!</p>
                        </div>

                        <div className="cotent-wrapper">
                            <div className="sections d-flex flex-wrap">
                                {/* sec item */}
                                <div className="sec-item col-12 col-sm-6 col-md-4 px-0">
                                    <div className="inner text-center">
                                        <div className="img-sec rounded-circle bg-white d-inline-flex align-items-center justify-content-center overflow-hidden">
                                            <img src={img_restaurant} alt="" className='img-fluid img-fluid-height' />
                                        </div>

                                        <div className="text-sec">
                                            <p className="head">Global Restaurant Guide</p>
                                            <p className="desc"></p>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* sec item */}
                                <div className="sec-item col-12 col-sm-6 col-md-4 px-0">
                                    <div className="inner text-center">
                                        <div className="img-sec rounded-circle bg-white d-inline-flex align-items-center justify-content-center overflow-hidden">
                                            <img src={img_bottles} alt="" className='img-fluid img-fluid-height' />
                                        </div>

                                        <div className="text-sec">
                                            <p className="head">Social Alcohol Guide</p>
                                            <p className="desc"></p>
                                        </div>
                                    </div>
                                </div>

                                {/* sec item */}
                                <div className="sec-item col-12 col-sm-6 col-md-4 px-0">
                                    <div className="inner text-center">
                                        <div className="img-sec rounded-circle bg-white d-inline-flex align-items-center justify-content-center overflow-hidden">
                                            <img src={img_heart} alt="" className='img-fluid img-fluid-height' />
                                        </div>

                                        <div className="text-sec">
                                            <p className="head">Complete Health Guide</p>
                                            <p className="desc"></p>
                                        </div>
                                    </div>
                                </div>

                                {/* sec item */}
                                <div className="sec-item col-12 col-sm-6 col-md-4 px-0">
                                    <div className="inner text-center">
                                        <div className="img-sec rounded-circle bg-white d-inline-flex align-items-center justify-content-center overflow-hidden">
                                            <img src={img_burger} alt="" className='img-fluid img-fluid-height' />
                                        </div>

                                        <div className="text-sec">
                                            <p className="head">Eat – In – Portion Guide</p>
                                            <p className="desc"></p>
                                        </div>
                                    </div>
                                </div>

                                {/* sec item */}
                                <div className="sec-item col-12 col-sm-6 col-md-4 px-0">
                                    <div className="inner text-center">
                                        <div className="img-sec rounded-circle bg-white d-inline-flex align-items-center justify-content-center overflow-hidden">
                                            <img src={img_travel} alt="" className='img-fluid img-fluid-height' />
                                        </div>

                                        <div className="text-sec">
                                            <p className="head">Travel Guide</p>
                                            <p className="desc"></p>
                                        </div>
                                    </div>
                                </div>

                                {/* sec item */}
                                <div className="sec-item col-12 col-sm-6 col-md-4 px-0">
                                    <div className="inner text-center">
                                        <div className="img-sec rounded-circle bg-white d-inline-flex align-items-center justify-content-center overflow-hidden">
                                            <img src={img_ring} alt="" className='img-fluid img-fluid-height' />
                                        </div>

                                        <div className="text-sec">
                                            <p className="head">Wedding & Party Guide</p>
                                            <p className="desc"></p>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}
