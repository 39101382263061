import React, { useEffect, useState } from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import { getUserAssesmentForm, postUserAssesmentForm } from 'utlis/apis/API_common';

function FourStep(props){
    const [stepFourData, setStepFourData] = useState(props.data);
    const [message, setMessage] = useState(undefined);
    useEffect(() => {
      getUserAssesmentForm(props.commonToken)
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          if (data && data.assesmentData) {
            console.log(data.assesmentData[0]);
  
            setStepFourData({
              ...data.assesmentData[0],
            });
          }
        })
        .catch((er) => console.log(er));
    }, []);
  
    const saveStepFour = () => {
      postUserAssesmentForm(props.commonToken, stepFourData)
        .then((res) => {
        //   props.next();
          console.log(res);
          if(res){
            setMessage("Form is successfully updated the information!");
          }
        })
        .catch((er) => console.log(er));
    };
    
    return (
        <Table striped bordered hover responsive>
            {message !== undefined && <div class="alert alert-success" role="alert">{message}</div>}
        <tbody>
            <tr>
                <td> Pizza / Burger</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, pizzaBurger_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.pizzaBurger_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, pizzaBurger_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.pizzaBurger_frqncy} /></td>
            </tr>
            <tr>
                <td>         Biscuits/ Cookies</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, biscuitsCookies_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.biscuitsCookies_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, biscuitsCookies_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.biscuitsCookies_frqncy} /></td>
            </tr>
            <tr>
                <td>         Roti/ Chapati</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, rotiChapati_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.rotiChapati_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, rotiChapati_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.rotiChapati_frqncy} /></td>
            </tr>
            <tr>
                <td>         Parathas</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, parathas_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.parathas_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, parathas_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.parathas_frqncy} /></td>
            </tr>
            <tr>
                <td>         Chaat [Bhel, Sev Puri, Paani Puri]</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, chaat_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.chaat_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, chaat_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.chaat_frqncy} /></td>
            </tr>
            <tr>
                <td>         Sprouts/ Pulses</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, sproutsPulses_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.sproutsPulses_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, sproutsPulses_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.sproutsPulses_frqncy} /></td>
            </tr>
            <tr>
                <td>         Rice </td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, rice_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.rice_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, rice_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.rice_frqncy} /></td>
            </tr>
            <tr>
                <td>         Instant Noodles</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, instantNoodles_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.instantNoodles_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, instantNoodles_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.instantNoodles_frqncy} /></td>
            </tr>
            <tr>
                <td>         Bread/ Pav</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, breadPav_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.breadPav_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, breadPav_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.breadPav_frqncy} /></td>
            </tr>
            <tr>
                <td>         Paneer</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, paneer: e.target.value } })} value={stepFourData.dietaryHabits?.paneer} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, paneer_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.paneer_frqncy} /></td>
            </tr>
            <tr>
                <td>         Cheese</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, cheese_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.cheese_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, cheese_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.cheese_frqncy} /></td>
            </tr>
            <tr>
                <td>         Indian Mithai</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, indianMithai_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.indianMithai_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, indianMithai_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.indianMithai_frqncy} /></td>
            </tr>
            <tr>
                <td>         Fruits</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, fruits_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.fruits_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, fruits_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.fruits_frqncy} /></td>
            </tr>
            <tr>
                <td>         Fried Snacks [Eg. Chips, Sev, Pakoda, etc.]</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, friedSnacks_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.friedSnacks_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, friedSnacks_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.friedSnacks_frqncy} /></td>
            </tr>
            <tr>
                <td>        Nuts/ Dry Fruits</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, nutsDryFruits_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.nutsDryFruits_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, nutsDryFruits_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.nutsDryFruits_frqncy} /></td>
            </tr>
            <tr>
                <td>         Aerated Beverages [Eg. Pepsi / Coke]</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, aeratedBeverages_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.aeratedBeverages_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, aeratedBeverages_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.aeratedBeverages_frqncy} /></td>
            </tr>
            <tr>
                <td>        Preserved Juices [Eg. Tropicana/ Real]</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, preservedJuices_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.preservedJuices_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, preservedJuices_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.preservedJuices_frqncy} /></td>
            </tr>
            <tr>
                <td>         Tea</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, tea_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.tea_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, tea_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.tea_frqncy} /></td>
            </tr>
            <tr>
                <td>         Coffee</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, coffee_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.coffee_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, coffee_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.coffee_frqncy} /></td>
            </tr>
            <tr>
                <td>         Health Drinks</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, healthDrinks_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.healthDrinks_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, healthDrinks_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.healthDrinks_frqncy} /></td>
            </tr>
            <tr>
                <td>        Chocolate </td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, chocolate_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.chocolate_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, chocolate_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.chocolate_frqncy} /></td>
            </tr>
            <tr>
                <td>         Ghee</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, ghee_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.ghee_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, ghee_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.ghee_frqncy} /></td>
            </tr>
            <tr>
                <td>         Butter</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, butter_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.butter_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, butter_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.butter_frqncy} /></td>
            </tr>
            <tr>
                <td>         Milk</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, milk_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.milk_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, milk_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.milk_frqncy} /></td>
            </tr>
            <tr>
                <td>         Curd</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, curd_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.curd_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, curd_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.curd_frqncy} /></td>
            </tr>
            <tr>
                <td>         Breakfast Cereal [Oats/Muesli/Wheat Flakes/Corn Flakes]</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, breakfastCereal_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.breakfastCereal_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, breakfastCereal_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.breakfastCereal_frqncy} /></td>
            </tr>
            <tr>
                <td>         Ice Cream</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, iceCream_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.iceCream_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, iceCream_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.iceCream_frqncy} /></td>
            </tr>
            <tr>
                <td>        Frozen Food</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, frozenFood_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.frozenFood_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, frozenFood_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.frozenFood_frqncy} /></td>
            </tr>
            <tr>
                <td>         Seafood</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, seafood_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.seafood_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, seafood_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.seafood_frqncy} /></td>
            </tr>
            <tr>
                <td>         Fish</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, fish_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.fish_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, fish_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.fish_frqncy} /></td>
            </tr>
            <tr>
                <td>         Chicken </td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, chicken_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.chicken_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, chicken_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.chicken_frqncy} /></td>
            </tr>
            <tr>
                <td>         Mutton </td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, mutton_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.mutton_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, mutton_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.mutton_frqncy} /></td>
            </tr>
            <tr>
                <td>         Pork</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, pork_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.pork_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, pork_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.pork_frqncy} /></td>
            </tr>
            <tr>
                <td>         Eggs</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, eggs_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.eggs_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, eggs_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.eggs_frqncy} /></td>
            </tr>
            <tr>
                <td>         Midnight Snacks [Eg. Popcorn, Chiwda, Chakli, Etc.]</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, midnightSnacks_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.midnightSnacks_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, midnightSnacks_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.midnightSnacks_frqncy} /></td>
            </tr>
            <tr>
                <td>         Cakes/ Pastries</td>
                <td><Form.Control plaintext placeholder="Amount" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, cakesPastries_amnt: e.target.value } })} value={stepFourData.dietaryHabits?.cakesPastries_amnt} /></td>
                <td colSpan={2}><Form.Control plaintext placeholder="Frequency" onChange={(e) => setStepFourData({ ...stepFourData, dietaryHabits: { ...stepFourData.dietaryHabits, cakesPastries_frqncy: e.target.value } })} value={stepFourData.dietaryHabits?.cakesPastries_frqncy} /></td>
            </tr>
            
        <tr>
          <td colSpan={4} className="text-right">
            {message !== undefined && <div class="alert alert-success" role="alert">{message}</div>}
            <Button onClick={() => props.prev()} className='mr-5'>Previous</Button>
            <Button onClick={saveStepFour}>Save</Button>
          </td>
        </tr>
        </tbody>
    </Table>
    )
}

export default FourStep
