import React, { useEffect, useState } from 'react';
// redux
import { connect } from 'react-redux';

import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getCategories } from 'utlis/apis/API_recipes';
import { addRecipes } from 'redux/actions/actionRecipes';

function RecipesCategories(props) {
    const {
        addRecipes
    } = props

    const [categories, setCatgories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getCategories(1, 'recipe').then(res => {
            setCatgories(res.data.items);
            setLoading(false);
        }).catch(err => {
            console.log('error occured ', err.message)
        });
    }, []);
    return (
        <div className='recipes-cats w-100 mb-3 mb-lg-4'>
            {
                (loading) ? (
                    <div className='text-center'>
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <React.Fragment>
                        <ul className='list-container d-flex flex-wrap justify-content-center mx-auto'>
                            <li className={`mx-2 mb-2`}>
                                <Link to={`/recipes`} className='link text-decoration-none' onClick={() => {
                                    addRecipes([])
                                    window.location.reload()
                                }}>All</Link>
                            </li>
                            {
                                categories?.length ? categories.map(item => (
                                    <li key={item.id} className={`mx-2 mb-2`}>
                                        <Link to={`/recipes/${item.slug}`} className='link text-decoration-none'>{item.name}</Link>
                                    </li>
                                )) : (
                                    <React.Fragment>
                                        <p className='mb-3'>No recipes found</p>
                                    </React.Fragment>
                                )
                            }
                        </ul>
                    </React.Fragment>
                )
            }
        </div>
    )
}

const getDataFromStore = state => {
    return {

    };
}

const dispatchActionsToProps = dispatch => {
    return {
        addRecipes: recipesArray => dispatch(addRecipes(recipesArray)),
    }
}

export default connect(getDataFromStore, dispatchActionsToProps)(RecipesCategories)