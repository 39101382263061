import React, { Component } from 'react'

// styles
import '../styles/recipes-styles.css'

// bootstrap
import { Container } from 'react-bootstrap'

// icons : feather
import FeatherIcon from 'feather-icons-react';

export default class RecipesSerach extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: '',
            switch: false
        }
    }
    debounce = (callback, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => callback(...args), delay);
        };
    };

    d = this.debounce(text => {
        const type = this.state.switch ? 'non-veg' : 'veg';
        this.props.onSearch({ s: text, type: type });
    }, 500)

    handleChange = e => {
        this.setState({ search: e.target.value });
        this.d(e.target.value);
    };

    handleSwitchChange = ev => {
        const isChecked = ev.target.checked

        this.setState({
            ...this.state,
            switch: isChecked
        });
        const type = isChecked ? 'non-veg' : 'veg';
        this.props.onSearch({ s: this.state.search, type: type });
    }

    render() {
        return (
            <section id="recipes-search" className="st-bg-slate py-3 pt-lg-5">
                <Container>
                    <div className="recipes-search d-flex align-items-center justify-content-center">
                        <div className="recipes-search-main d-inline-flex flex-wrap bg-white pl-md-2">

                            {/* field 2 */}
                            <div className="frac py-md-1 switch">
                                <div className="inner st-form d-flex align-items-center pr-md-3 mr-md-1 position-relative">
                                    <label className="d-flex align-items-center st-switch font-size-15 st-text-gray">
                                        <input
                                            type="checkbox"
                                            className="d-none"
                                            checked={this.state.switch}
                                            onChange={this.handleSwitchChange}
                                        />
                                        <span className="bar d-block">
                                            <span className="round rounded-circle d-block"></span>
                                        </span>
                                        <span className="text d-block ml-2">
                                            {
                                                this.state.switch ? "Veg" : "Non Veg"
                                            }
                                        </span>
                                    </label>
                                </div>
                            </div>

                            {/* field */}
                            <div className="frac py-md-1 input">
                                <div className="inner pr-md-2 mr-md-2 st-form with-icon-left position-relative">
                                    <input type="text" className="form-control font-size-15" placeholder="Search Recipe"
                                        onChange={this.handleChange} />
                                    <div className="icon position-absolute">
                                        <FeatherIcon
                                            icon="search"
                                            size="14"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* btns */}
                            {/* <div className="btns">
                                <button className="btn st-btn st-btn-primary font-size-15 font-weight-600 h-100">Search Now</button>
                            </div> */}
                        </div>
                    </div>
                </Container>
            </section>
        )
    }
}
