import React, { Component } from 'react'

// styles
import '../styles/recipes-styles.css'

// bootstrap
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap'

// router
import { Link } from 'react-router-dom'

// redux
import { connect } from 'react-redux';

// images
// no data found image
import noImgFound from 'assets/images/no-image-found-logo.png'

import useQuery from 'utlis/helpers/UseQueryHook';
import RecipesCategories from './RecipesCategories';


class RecipesList extends Component {
    render() {
        const {
            loading,
            recipes,

            getMoreRecipes,
            showMoreBtnDisabled,

            showMoreBtnLoading,
            showMoreBtnText
        } = this.props



        return (
            <section id="recipes-list" className="st-bg-slate py-3 py-lg-5">
                <Container>
                    <Row className="recipes-list d-flex align-items-start justify-content-center">

                        <RecipesCategories />

                        {
                            (loading) ? (
                                <Spinner animation="border" />
                            ) : (
                                <React.Fragment>
                                    {
                                        recipes?.length ? recipes.map(item => (
                                            /* RECIPE ITEM */
                                            <Col key={item.id} xs={12} sm={6} md={4} lg={3} className="recipe-item mb-3 mb-lg-5">
                                                <Link to={'/recipe-details/' + item.slug} className="inner d-block text-decoration-none bg-white st-block-box-shadow overflow-hidden">
                                                    {/* img sec */}
                                                    {
                                                        (item.coverImage === null) ? (
                                                            <div className="img-sec text-center overflow-hidden bg-white">
                                                                <Image src={noImgFound} fluid style={{ maxHeight: '100%' }} />
                                                            </div>
                                                        ) : (
                                                            <div className="img-sec text-center overflow-hidden">
                                                                <Image src={item.fullUrlImage} />
                                                            </div>
                                                        )
                                                    }

                                                    {/* text sec */}
                                                    <div className="text-sec py-3 px-2 text-center">
                                                        <p className="text-uppercase font-size-17 font-weight-600">
                                                            {item.title}
                                                        </p>
                                                    </div>
                                                </Link>
                                            </Col>
                                        )) : (
                                            <React.Fragment>
                                                <p className='mb-3'>No recipes found</p>
                                            </React.Fragment>
                                        )
                                    }

                                    {/* SHOW MORE BTN */}
                                    <Col xs={12} className="show-more-btn text-center">
                                        <button
                                            onClick={getMoreRecipes}
                                            disabled={showMoreBtnDisabled}
                                            className="btn font-size-17 h-100 st-btn st-btn-lg st-btn-primary text-capitalize">
                                            {
                                                showMoreBtnLoading &&
                                                <Spinner animation="border" size="sm" className="position-relative mr-2" style={{ top: -2 }} />
                                            }
                                            <span>{showMoreBtnText}</span>
                                        </button>
                                    </Col>
                                </React.Fragment>
                            )
                        }

                    </Row>
                </Container>
            </section >
        )
    }
}

const GetCategoryId = () => {
    const query = useQuery()
    return query.get("category");
}

const getDataFromStore = state => {
    return {
        recipes: state.recipes.recipes,
    };
}

export default connect(getDataFromStore, null)(RecipesList)