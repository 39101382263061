// AXIOS
import axios from "axios";

// api url
import { apiUrl } from "./constants";

// getting initial recipies
export async function getRecipes(token, page, query = null) {
  if (page) {
    query = query ? `p=${page}&s=${query.s}&tag=${query.type}` : `p=${page}`;
    const stories = await axios.get(`${apiUrl}getposts/recipe?${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return stories;
  } else {
    console.log("Please add required parameters");
  }
}
// getting initial recipies
export async function getRecipesByCategory(token, query) {
  if (query.page) {
    let q = '';
    if (query.page) {
      q += `p=${query.page}`;
    }
    if (query.category) {
      q += `&category=${query.category}`;
    }
    if (query.s) {
      q += `&s=${query.s}`;
    }
    if (query.tag) {
      q += `&tag=${query.tag}`;
    }
    if (query.type) {
      q += `&tag=${query.type}`;
    }
    console.log('q ',q)
    const stories = await axios.get(`${apiUrl}getposts/recipe?${q}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return stories;
  } else {
    console.log("Please add required parameters");
  }
}

// getting initial recipies
export async function getCategories(page, type) {
  if (page) {
    const stories = await axios.get(
      `${apiUrl}getposts/category/${type}?p=${page}`
    );

    return stories;
  } else {
    console.log("Please add required parameters");
  }
}

// getting more recipies
export async function getRecipe(token, slug) {
  if (slug) {
    const stories = await axios.get(apiUrl + "getpost/" + slug, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return stories;
  } else {
    console.log("Please add required parameters");
  }
}

// getting aarogyam list
export async function getAarogyamList(token) {
  const stories = await axios.get(apiUrl + "api/aarogyam", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return stories;
}
