import React, { Component } from 'react'

// bootstrap
import { Container, Image, Spinner } from 'react-bootstrap'

// styles
import '../../styles/my-account-styles.css'

// componetns
import PageBanner from 'components/CommonComponents/PageBanner/PageBanner'

// images
import mobileIcon from 'assets/images/icons/hamburger-menu-icon.png'

// account left menu
import AccountLeftMenu from '../../includes/AccountLeftMenu';

// redux
import { connect } from 'react-redux';

// apis
import { getAarogyamList } from 'utlis/apis/API_recipes'

// icons : feather
import FeatherIcon from 'feather-icons-react';

class AarogyamList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobileMenuVisible: false,
            pdfData: []
        }

        // FUNCTIONS BINDINGS
        this.toggleSideMenu = this.toggleSideMenu.bind(this)
    }

    componentDidMount() {
        // loading
        this.setState({
            loading: true,
        })

        // GETTING DATA
        getAarogyamList(this.props.commonToken).then(res => {
            this.setState({
                loading: false,
                pdfData: res?.data?.items ?? []
            })

        }).catch(err => {
            console.log('error occured ', err.message)
        })
    }


    // for mobile side menu
    toggleSideMenu = () => {
        // checking Window Width for mobile menu
        if (window && window.innerWidth < 768) {
            this.setState({
                isMobileMenuVisible: !this.state.isMobileMenuVisible
            })
        }
    }


    render() {
        const { pdfData, loading } = this.state;
        return (
            <>
                {/* HEADER */}
                {/* <Header /> */}

                {/* BANNER */}
                <PageBanner
                    pageTitle="My Account"
                    lastLinkName="My Account"
                    lastLinkPath="/my-account"
                    lastLinkName2="Sessions"
                    lastLinkPath2="/my-account/sessions"
                />


                {/* MY ACCOUT SECTION */}
                <section id="my-account" className="ST_def-pad-TB">
                    <Container>
                        <div className="my-account d-flex flex-wrap position-relative st-bg-slate overflow-hidden">
                            {/* mobile menu */}
                            <div className={`ma-mobile-icon position-absolute d-md-none ${this.state.isMobileMenuVisible ? 'active' : ''}`}>
                                <Image src={mobileIcon} fluid width="25"
                                    onClick={this.toggleSideMenu}
                                />
                            </div>

                            {/* LEFT SECTION */}
                            <div className={`ma-leftbar position-absolute h-100 ${this.state.isMobileMenuVisible ? 'active' : ''}`}>
                                <div className="inner border-right st-bg-slate overflow-auto h-100">
                                    <AccountLeftMenu isMobileMenuVisible={this.state.isMobileMenuVisible} />
                                </div>
                            </div>

                            {/* CONTENT SECTION */}
                            <div className={`ma-content aarogyam-list-content ${this.state.isMobileMenuVisible ? 'active' : ''}`}>
                                <div className="inner p-3 p-lg-5">
                                    <div className="st-heading-wrapper mb-3 mb-lg-4">
                                        <p className="st-heading heading-xs st-text-primary font-family-sec font-family-secondary-medium">Aarogyam List</p>
                                        <p className="desc mt-1">
                                        </p>
                                    </div>

                                    <div className="aarogyam-list-container d-flex flex-wrap">
                                        <div className="pdf-container d-flex flex-wrap">
                                            {/* loading */}
                                            {
                                                loading && <div style={{ paddingLeft: 10, paddingRight: 10 }}>Loading...</div>
                                            }

                                            {
                                                /* item */
                                                pdfData?.length ? pdfData?.map(item => (
                                                    <div key={item.id} className="pdf-item">
                                                        <div className="inner">
                                                            {/* icon */}
                                                            <div className="icon">
                                                                <FeatherIcon
                                                                    icon="file-text"
                                                                    size="26"
                                                                />
                                                            </div>

                                                            {/* text */}
                                                            <div className="text">
                                                                <p>{item.title}</p>
                                                                <a href={item.pdf} target="_blank">Download</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : (
                                                    <>
                                                        {!loading && <div style={{ paddingLeft: 10, paddingRight: 10 }}>Sorry no data found</div>}
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </>
        )
    }
}

const getDataFromStore = state => {
    return {
        commonToken: state.auth.commonToken
    };

}

export default connect(getDataFromStore, null)(AarogyamList)