import React, { Component } from 'react'

export default class AssesmentForm extends Component {
    constructor(props) {
        super(props)

    }




    render() {

        return (
            <>
                <table className="table">
                    <tbody>
                        <tr>
                            <td width="50%">
                                <h4>NUTRITION & LIFESTYLE ANALYSIS</h4>
                            </td>
                            <td>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Work Status*</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="work" id="work_yes" value="yes" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.work == "yes"} />
                                            <label className="form-check-label" >
                                                Working
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="work" id="work_no" value="no" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.work == "no"} />
                                            <label className="form-check-label" >
                                                No Working
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="work" id="work_from_hm" value="work_hm" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.work == "work_hm"} />
                                            <label className="form-check-label" >
                                                Working from Home
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Occupation / Profession*</label>
                                    <input type="text" className="form-control work_child" id="occ" name="occ" onChange={ev => this.props.fieldChangeHandler(ev)} required />
                                </div>

                                <div className="form-group col-md-12">
                                    <label >Work Timings*</label>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <input type="text" className="form-control mb-2 work_child" id="from" name="work_timing_from" required placeholder="from" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="text" className="form-control work_child" id="to" name="work_timing_to" required placeholder="to" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Desingnation</label>
                                    <input type="text" className="form-control work_child" id="desg" name="desg" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label >Does Your Job Include Frequent Travel?</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input work_child" type="radio" name="travel_incl" id="travel_yes" value="yes" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.travel_incl == "yes"} />
                                            <label className="form-check-label" >
                                                Yes 
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input work_child" type="radio" name="travel_incl" id="travel_no" value="no" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.travel_incl == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Lifestyle</label>
                                    <select className="custom-select" name="lifestyl" onChange={ev => this.props.fieldChangeHandler(ev)}>
                                        <option >select option</option>
                                        <option value="desk_job_six_hour">If You Have A Desk Job Or Are Needed To Sit For 6 hours</option>
                                        <option value="travel_not_seated">If You Have To travel with work Or You are not seated for long hours</option>
                                        <option value="travel_not_seated_exercise">If You Have To travel with work Or You are not seated for long hours along with regular physical exercises</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Do You Carry Meals To Office?*</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="carry_meal" id="carry_meal" value="yes" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.carry_meal == "yes"} />
                                            <label className="form-check-label">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="carry_meal" id="carry_meal" value="no" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.carry_meal == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Eating Habit</label>
                                    <select className="custom-select" name="eating_habit" onChange={ev => this.props.fieldChangeHandler(ev)}>
                                        <option >select option</option>
                                        <option value="non_veg">Non-Vegetarian</option>
                                        <option value="vegetarian">Vegetarian</option>
                                        <option value="eggetarian">Eggetarian</option>
                                        <option value="vegan">Vegan</option>
                                    </select>
                                </div>
                                <div className={` form-group col-md-12 ${this.props.fmdata.eating_habit == 'non_veg' ? "d-block" : "d-none"}`} >
                                    <label >Frequency Of Eating Non – Veg:</label>
                                    <input type="text" className="form-control" id="eating_non_veg" name="eating_non_veg" placeholder="Once a Month" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className={` form-group col-md-12 ${this.props.fmdata.eating_habit == 'eggetarian' || this.props.fmdata.eating_habit == 'non_veg' ? "d-block" : "d-none"}`} >
                                    <label >Frequency Of Eating Eggs:  </label>
                                    <input type="text" className="form-control" id="eating_egg" name="eating_egg" placeholder="Once a week" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>

                                <div className={` form-group col-md-12 ${this.props.fmdata.eating_habit == 'non_veg' ? "d-block" : "d-none"}`} >
                                    <label >Preferred Meats:</label>
                                    <input type="text" className="form-control" id="pref_meat" name="pref_meat" placeholder="Eg. Chicken, Mutton, Fish, Etc." onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Water Intake Per Day*</label>
                                    <input type="text" className="form-control" id="water_pr_day" name="water_pr_day" required placeholder="More Than 8 Glasses" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Do You Smoke?*</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="smoke" id="smoke" value="yes" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.smoke == "yes"} />
                                            <label className="form-check-label" >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="smoke" id="smoke" value="no" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.smoke == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Do You Consume Alcohol?*</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="alcohol" id="alcohol" value="yes" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.alcohol == "yes"} />
                                            <label className="form-check-label"  >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="alcohol" id="alcohol" value="no" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.alcohol == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Do You fast?</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="fast" id="fast" value="yes" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.fast == "yes"} />
                                            <label className="form-check-label" >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="fast" id="fast" value="no" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.fast == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr id="child_">
                            <td>
                                <div className="form-group col-md-12">
                                    <label >How Often Do You Fast?</label>
                                    <input type="text" className="form-control fast_child" id="often_fast" name="often_fast" placeholder="Once a month" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Foods Consumed During Fast*</label>
                                    <input type="text" className="form-control fast_child" id="food_during_fast" name="food_during_fast" required placeholder="Eg.Sabudana, Nuts, Fruits, Etc." onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >How Often Do You Visit A Restaurant / Order In?*</label>
                                    <input type="text" className="form-control fast_child" id="often_visit_rastra" name="often_visit_rastra" placeholder="Once Or Twice In A Month" required onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >What Are Your Preferred Cuisines?</label>
                                    <input type="text" className="form-control" id="pref_cuisines" name="pref_cuisines" required placeholder="Eg. Indian, Chinese, Thai, etc." onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Who Cooks At Home?*</label>
                                    <input type="text" className="form-control" id="cook_at_home" name="cook_at_home" placeholder="cook" required onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Are You A Quick / Slow Eater?*</label>
                                    <input type="text" className="form-control" id="eater_speed" name="eater_speed" required placeholder="quick or slow eater" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Hunger Peak Timing</label>
                                    <input type="text" className="form-control" id="hunger_time" name="hunger_time" placeholder="06:30 am/pm" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Do You Indulge In Any Other Activity While Eating? If Yes, Please Select*</label>
                                    <select className="custom-select" name="eat_time_activity" onChange={ev => this.props.fieldChangeHandler(ev)}>
                                        <option value="nothing">select option</option>
                                        <option value="mobile_phone">Mobile Phone</option>
                                        <option value="television">Television</option>
                                        <option value="music">Music</option>
                                        <option value="videogames">Videogames</option>
                                        <option value="newspaper">Newspaper</option>
                                        <option value="work">Work</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Any Food Aversions (Foods You Dislike)*</label>
                                    <input type="text" className="form-control" id="dislike_food" name="dislike_food"
                                        required onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Any Food Preferences (Foods You Like)*</label>
                                    <input type="text" className="form-control" id="food_like" name="food_like" required onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >What Meals Do You Carry To Office?*</label>
                                    <select className="custom-select" name="office_carry_meal" required onChange={ev => this.props.fieldChangeHandler(ev)} >
                                        <option >select option</option>
                                        <option value="breakfast">Breakfast</option>
                                        <option value="mid_morn_snack">Mid – Morning Snacks</option>
                                        <option value="lunch">Lunch</option>
                                        <option value="eve_snack">Evening Snack</option>
                                        <option value="dinner">Dinner</option>
                                        <option value="any_other">Any Other</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Do You Workout/ Exercise Regularly?*</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="exercise" id="exercise" value="yes" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.exercise == "yes"} />
                                            <label className="form-check-label" >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="exercise" id="exercise" value="no" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.exercise == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Workout Type*</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input exe_child" type="radio" name="workout_type" id="workout_type" value="cardio_workout" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.workout_type == "cardio_workout"} required />
                                            <label className="form-check-label" >
                                                Cardio Workout
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input exe_child" type="radio" name="workout_type" id="workout_type" value="weight_training" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.workout_type == "weight_training"} required />
                                            <label className="form-check-label" >
                                                Weight Training
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Exercise Name</label>
                                    <select className={` custom-select exe_cardio exe_child ${this.props.fmdata.workout_type == 'cardio_workout' ? "d-block" : "d-none"}`} name="excercise" required onChange={ev => this.props.fieldChangeHandler(ev)}>
                                        <option >select option</option>
                                        <option value="cycling">Cycling</option>
                                        <option value="dance">Dance</option>
                                        <option value="elliptical">Elliptical</option>
                                        <option value="gym_cycle">Gym Cycle</option>
                                        <option value="running">Running</option>
                                        <option value="skipping">Skipping</option>
                                        <option value="swimming">Swimming</option>
                                        <option value="treadmill">Treadmill</option>
                                        <option value="walk">Walk</option>
                                        <option value="zumba">Zumba</option>
                                    </select>
                                    <select className={` custom-select exe_weight exe_child ${this.props.fmdata.workout_type == 'weight_training' ? "d-block" : "d-none"}`} name="excercise" required onChange={ev => this.props.fieldChangeHandler(ev)}>
                                        <option >select option</option>
                                        <option value="pilates">Pilates</option>
                                        <option value="yoga">Yoga</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Frequency</label>
                                    <input type="text" className="form-control exe_child" id="exe_frequency" name="exe_frequency" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Duration</label>
                                    <input type="text" className="form-control exe_child" id="exe_duration" name="exe_duration" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Timing</label>
                                    <input type="text" className="form-control exe_child" id="exe_timing" name="exe_timing" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Any other Excercise*</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input exe_child" type="radio" name="exec_other" id="exec_other" value="yes" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.exec_other == "yes"} />
                                            <label className="form-check-label" >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input exe_child" type="radio" name="exec_other" id="exec_other" value="no" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.exec_other == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Other Excercise Name</label>
                                    <input type="text" className="form-control exe_child exec_other_child" id="exe_other_name" name="exe_other_name" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Frequency</label>
                                    <input type="text" className="form-control exe_child exec_other_child" id="exe_other_frequency" name="exe_other_frequency" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Duration</label>
                                    <input type="text" className="form-control exe_child exec_other_child" id="exe_other_duration" name="exe_other_duration" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Timing</label>
                                    <input type="text" className="form-control exe_child exec_other_child" id="exe_other_timing" name="exe_other_timing" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table">
                    <tbody>
                        <tr>
                            <td width="50%">
                                In Case You Are Suffering From Any Medical Issues, Please Enlist Them And Elaborate Whenever Necessary.
                                <br />
                                This Will Help Us Plan Your Diets Giving Due Importance To The Condition.
                            </td>
                            <td>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Do You Have Any Medical / Health Issues?*</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="medical_issue" id="medical_issue" value="yes" required onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.medical_issue == "yes"} />
                                            <label className="form-check-label" >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="medical_issue" id="medical_issue" value="no" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.medical_issue == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <select className="custom-select medical_issue_child" name="medical_issue_name" required onChange={ev => this.props.fieldChangeHandler(ev)}>
                                        <option >select option</option>
                                        <option value="any_surg">Any  Kind  Of Surgery</option>
                                        <option value="fatigue">Chronic Fatigue</option>
                                        <option value="diabetes">Diabetes</option>
                                        <option value="diffi_dur_exe">Difficulty During Physical Exercise</option>
                                        <option value="faty_liver">Fatty Liver</option>
                                        <option value="high_hp">High Blood Pressure</option>
                                        <option value="high_prolactin">High Prolactin</option>
                                        <option value="high_uric_acid">High Uric Acid / Gout</option>
                                        <option value="breating">History Of Breathing / Lung Aliments</option>
                                        <option value="hyperthyro">Hyperthyroidism</option>
                                        <option value="hyporthyro">Hypothyroidism</option>
                                        <option value="infertility">Infertility</option>
                                        <option value="kidney_dis">Kidney Disorder</option>
                                        <option value="low_bld_press">Low Blood Pressure</option>
                                        <option value="ortho_ali">Orthopedic Aliment</option>
                                        <option value="pcs">PCOS</option>
                                        <option value="strke">Stroke</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-12">
                                    <label >Any Other (please specify)</label>
                                    <input type="text" className="form-control medical_issue_child" id="other_medic_prob" name="other_medic_prob" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Bowel / Digestive Complaints</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input medical_issue_child" type="radio" name="bowel" id="bowel" value="yes" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.bowel == "yes"} />
                                            <label className="form-check-label" >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input medical_issue_child" type="radio" name="bowel" id="bowel" value="no" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.bowel == "no"} />
                                            <label className="form-check-label"  >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Any Proven Food Allergies</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="food_allergy" id="food_allergy" value="yes" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.food_allergy == "yes"} />
                                            <label className="form-check-label" >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="food_allergy" id="food_allergy" value="no" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.food_allergy == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className={` form-group col-md-12 ${this.props.fmdata.gender == 'female' ? "d-block" : "d-none"}`}>
                                    <label >Are Your Periods Regular?</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="period_regular" id="period_regular" value="yes" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.period_regular == "yes"} />
                                            <label className="form-check-label" >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="period_regular" id="period_regular" value="no" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdataperiod_regular == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={` form-group col-md-12 ${this.props.fmdata.gender == 'female' ? "d-block" : "d-none"}`}>
                                    <label >Do You PMS?</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="pms" id="pms" value="yes" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.pms == "yes"} />
                                            <label className="form-check-label" >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="pms" id="pms" value="no" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.pms == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className={` form-group col-md-12 ${this.props.fmdata.gender == 'female' ? "d-block" : "d-none"}`}>
                                    <label >ARE YOU IN MENOPAUSE?</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="menopause" id="menopause" value="yes" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.menopause == "yes"} />
                                            <label className="form-check-label" >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="menopause" id="menopause" value="no" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.menopause == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={` form-group col-md-12 ${this.props.fmdata.gender == 'female' ? "d-block" : "d-none"}`}>
                                    <label >Last Menstrual Periods (LMP)</label>
                                    <div className="form-group col-md-12">
                                        <input type="text" className="form-control" id="last_period_date" name="last_period_date" placeholder="13 March" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label>Any Test OR Reports Done By You Recently (Last 6 Months)?</label>
                                    <label >Test Name</label>
                                    <div className="form-group col-md-12">
                                        <input type="text" className="form-control mb-2" id="test_name" name="test_name" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                    </div>
                                    <label >Upload Test Photo</label>
                                    <div className="form-group col-md-12">
                                        <input type="file" className="form-control" id="report_img" name="report_img" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Are You On Medications?</label>
                                    <div className="d-flex">
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="medication" id="medication" value="yes" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.medication == "yes"} />
                                            <label className="form-check-label" >
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check flex-fill">
                                            <input className="form-check-input" type="radio" name="medication" id="medication" value="no" onChange={ev => this.props.changeHandler(ev)} checked={this.props.fmdata.medication == "no"} />
                                            <label className="form-check-label" >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className={` form-group col-md-12 ${this.props.fmdata.gender == 'female' ? "d-block" : "d-none"}`}>
                                    <label >Pregnancy History </label>
                                    <div className="form-group col-md-12">
                                        <input type="text" className="form-control mb-2 married_lady" id="pregnancy_history" name="pregnancy_history" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                    </div>
                                    <label >Number of Children</label>
                                    <div className="form-group col-md-12">
                                        <input type="text" className="form-control married_lady" id="num_child" name="num_child" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={` form-group col-md-12 ${this.props.fmdata.gender == 'female' ? "d-block" : "d-none"}`}>
                                    <label >Any Post Pregnancy Complications?*</label>
                                    <div className="form-group col-md-12">
                                        <select className="custom-select married_lady" name="post_pregnancy_complication" required onChange={ev => this.props.fieldChangeHandler(ev)}>
                                            <option >select option</option>
                                            <option value="preg_high_bp">High Blood Pressure</option>
                                            <option value="preg_diabetes">Gestation Diabetes</option>
                                            <option value="preg_thyroid">Thyroid Problems</option>
                                            <option value="preg_anemia">Anemia</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label >Any Other (please specify)</label>
                                    <div className="form-group col-md-12">
                                        <input type="text" className="form-control" id="other_post_preg_compication" name="other_post_preg_compication" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                    </div>
                                </div>
                            </td>
                            <td>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table">
                    <tbody>
                        <tr>
                            <td width="50%">
                                <div className="form-group col-md-12">
                                    Are You Taking Any Supplements Or Vitamins? If Yes, Please Fill In The Name And Type Of Supplement That You Are Taking, And For Which Particular Condition. Kindly Elaborate On The Comment Section If Deemed Necessary.
                                </div>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Supplement Name</label>
                                    <input type="text" className="form-control" id="supplement_name" name="supplement_name" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Dosage</label>
                                    <input type="text" className="form-control" id="supplement_dosage" name="supplement_dosage" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Frequency</label>
                                    <input type="text" className="form-control" id="supplement_frequency" name="supplement_frequency" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Timings</label>
                                    <input type="text" className="form-control" id="supplement_timings" name="supplement_timings" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Comments</label>
                                    <input type="text" className="form-control" id="comments" name="comments" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                <label >This Section Allows Us To Know The Foods You Eat And Its Frequency For Long Term Understanding Of Your Food Pattern.</label>
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-group col-md-12">
                                    <select className="custom-select" name="food_type" required onChange={ev => this.props.fieldChangeHandler(ev)}>
                                        <option >select option</option>
                                        <option value="pizza_burg">Pizza / Burger</option>
                                        <option value="biscuit_cooki">Biscuits / Cookies</option>
                                        <option value="roti_chapti">Roti / Chapati</option>
                                        <option value="parathas">Parathas</option>
                                        <option value="chaat">Chaat</option>
                                        <option value="sprout_pulse">Sprouts/ Pulses</option>
                                        <option value="rice">Rice</option>
                                        <option value="insta_noodle">Instant Noodles</option>
                                        <option value="bread_pav">Bread / Pav</option>
                                        <option value="paneer">Paneer</option>
                                        <option value="indian_mithai">Indian Mithai</option>
                                        <option value="fruit">Fruits</option>
                                        <option value="fried_snack">Fried Snacks</option>
                                        <option value="nuts_fruits">Nuts/ Dry Fruits</option>
                                        <option value="aerate_beverage">Aerated Beverages</option>
                                        <option value="preserv_juice">Preserved Juices</option>
                                        <option value="tea">Tea</option>
                                        <option value="coffee">Coffee</option>
                                        <option value="health_drink">Health Drinks</option>
                                        <option value="choclate">Chocolate</option>
                                        <option value="ghee">Ghee</option>
                                        <option value="butter">Butter</option>
                                        <option value="milk">Milk</option>
                                        <option value="curd">Curd</option>
                                        <option value="break_cereal">Breakfast Cereal</option>
                                        <option value="ice_cream">Ice Cream</option>
                                        <option value="frozen_food">Frozen Food</option>
                                        <option value="seafood">Seafood</option>
                                        <option value="fish">Fish</option>
                                        <option value="chicken">Chicken</option>
                                        <option value="mutton">Mutton</option>
                                        <option value="beef">Beef</option>
                                        <option value="pork">Pork</option>
                                        <option value="eggs">Eggs</option>
                                        <option value="mid_snacks">Midnight Snacks</option>
                                        <option value="cakes">Cakes / Pastries</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div className="form-group col-md-12">
                                    <label >Amount</label>
                                    <input type="text" className="form-control mb-2" id="food_amount" name="food_amount" required onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>

                                <div className="form-group col-md-12">
                                    <label >Frequency</label>
                                    <input type="text" className="form-control" id="food_frequency_long" name="food_frequency_long" required onChange={ev => this.props.fieldChangeHandler(ev)} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table">
                    <tbody>
                        <tr>
                            <td width="50%">
                                <div className="form-group col-md-12">
                                    <label >In Case We Have Not Covered Any Aspect Of Your Lifestyle, Please Describe It In The Box Below.</label>
                                    <div className="form-group col-md-12">
                                        <label><b>Note To Mentor And Dr. Nurvi:</b></label>
                                        <input type="text" className="form-control mb-2" id="cst_describe" name="cst_describe" onChange={ev => this.props.fieldChangeHandler(ev)} />
                                    </div>
                                </div>

                            </td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>
                                <button
                                    className="btn st-btn st-btn-primary text-uppercase font-family-secondary-bold font-size-14"
                                    onClick={ev => {
                                        // opening step 2
                                        this.props.openStep1Tab(ev)
                                    }}
                                >Prev</button>
                            </td>
                            <td>
                                <input type="submit" className="main-btn btn st-btn st-btn-primary form-control mb-2" id="submit" name="submit" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }
}