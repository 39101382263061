import React, { Component } from 'react'
import AppRoot from './components/AppRoot'

export default class App extends Component {
	render() {
		return (
			<AppRoot />
		)
	}
}
