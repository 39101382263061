import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import {
  getUserAssesmentForm,
  postUserAssesmentForm,
} from "utlis/apis/API_common";
const mealsCarryToOfficeList = [
  "Breakfast",
  "Mid – Morning Snacks",
  "Lunch",
  "Evening Snack",
  "Dinner",
  "Any Other",
];
function SecondStep(props) {
  const [stepSecondData, setStepSecondData] = useState(props.data);
  const [mealsCarryToOfficeListActive, setMealsCarryToOfficeListActive] =
    useState([]);

  useEffect(() => {
    getUserAssesmentForm(props.commonToken)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data && data.assesmentData) {
          console.log(data.assesmentData[0]);

          setStepSecondData({
            ...data.assesmentData[0],
          });
          setMealsCarryToOfficeListActive([
            ...data.assesmentData[0].nutritionLifeStyle.mealsCarryToOffice,
          ]);
        }
      })
      .catch((er) => console.log(er));
  }, []);

  const saveStepTwo = () => {
    postUserAssesmentForm(props.commonToken, stepSecondData)
      .then((res) => {
        props.next();
        console.log(res);
      })
      .catch((er) => console.log(er));
  };
  const onMealChange = (item) => {
    console.log(item);
    console.log(mealsCarryToOfficeListActive);
    if (mealsCarryToOfficeListActive.includes(item)) {
      setMealsCarryToOfficeListActive(
        mealsCarryToOfficeListActive.filter((remove) => remove !== item)
      );
    } else {
      setMealsCarryToOfficeListActive([...mealsCarryToOfficeListActive, item]);
    }
  };
  return (
    <Table striped bordered hover responsive>
      <tbody>
        <tr>
          <td>Work Status*:</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-working`}
              label={`Working`}
              name="workStatus"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    workStatus: "working",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.workStatus === "working"
                  ? true
                  : false
              }
            />
          </td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-not_working`}
              label={`Not Working`}
              name="workStatus"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    gender: "not_working",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.workStatus === "not_working"
                  ? true
                  : false
              }
            />
          </td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-working_from_home`}
              label={`Working from Home`}
              name="workStatus"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    gender: "working_from_home",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.workStatus ===
                "working_from_home"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Occupation / Profession*: </td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="Occupation"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    OccupationProfessio: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.OccupationProfessio}
            />
          </td>
        </tr>
        <tr>
          <td>Designation*:</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="Designation"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    designation: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.designation}
            />
          </td>
        </tr>
        <tr>
          <td>Work Timings:</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="1 hour"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    workTimings: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.workTimings}
            />
          </td>
        </tr>
        <tr>
          <td>Does Your Job Include Frequent Travel?*:</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-travel_yes`}
              label={`Yes`}
              name="jobIncludeFrequentTravel"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    jobIncludeFrequentTravel: "yes",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.jobIncludeFrequentTravel ===
                "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-travel_no`}
              label={`No`}
              name="jobIncludeFrequentTravel"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    jobIncludeFrequentTravel: "no",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.jobIncludeFrequentTravel ===
                "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Lifestyle*</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-Sedentary`}
              label={`Sedentary`}
              name="lifestyl"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    lifestyl: "sedentary",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.lifestyl === "sedentary"
                  ? true
                  : false
              }
            />
          </td>
          <td>
            <Form.Check
              type={"radio"}
              id={`Active`}
              label={`Active`}
              name="lifestyl"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    lifestyl: "active",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.lifestyl === "active"
                  ? true
                  : false
              }
            />
          </td>
          <td>
            <Form.Check
              type={"radio"}
              id={`moderatelyActive`}
              label={`Moderately Active`}
              name="lifestyl"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    lifestyl: "moderatelyActive",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.lifestyl ===
                "moderatelyActive"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>If You Have A Desk Job or Are Needed to Sit For 6 Hours</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder=""
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    deskJobOrNeededToSitForHours: e.target.value,
                  },
                })
              }
              value={
                stepSecondData.nutritionLifeStyle?.deskJobOrNeededToSitForHours
              }
            />
          </td>
        </tr>
        <tr>
          <td>Do You Carry Meals to Office?*</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-carryMeal_yes`}
              label={`Yes`}
              name="carryMealsToOffice"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    carryMealsToOffice: "yes",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.carryMealsToOffice === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-carryMeal_no`}
              label={`No`}
              name="carryMealsToOffice"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    carryMealsToOffice: "no",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.carryMealsToOffice === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Eating Habits*: </td>
          <td colSpan={3}>
            <select
              name="eatingHabit"
              className="form-control"
              onChange={(e) => {
                console.log(e.target.value);
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    eatingHabit: e.target.value,
                  },
                });
              }}
            >
              <option>Open this select menu</option>
              <option
                value="vegetarian"
                selected={
                  stepSecondData.nutritionLifeStyle.eatingHabit === "vegetarian"
                    ? "selected"
                    : ""
                }
              >
                Vegetarian
              </option>
              <option
                value="non_vegetarian"
                selected={
                  stepSecondData.nutritionLifeStyle.eatingHabit ===
                  "non_vegetarian"
                    ? "selected"
                    : ""
                }
              >
                Non – Vegetarian
              </option>
              <option
                value="eggetarian"
                selected={
                  stepSecondData.nutritionLifeStyle.eatingHabit === "eggetarian"
                    ? "selected"
                    : ""
                }
              >
                Eggetarian
              </option>
              <option
                value="vegan"
                selected={
                  stepSecondData.nutritionLifeStyle.eatingHabit === "vegan"
                    ? "selected"
                    : ""
                }
              >
                Vegan
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td>Frequency of Eating Eggs</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="5"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    frequencyOfEatingEggs: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.frequencyOfEatingEggs}
            />
          </td>
        </tr>
        <tr>
          <td>Frequency of Eating Non-Veg</td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="4"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    frequencyOfEatingNonVeg: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.frequencyOfEatingNonVeg}
            />
          </td>
        </tr>
        <tr>
          <td>Preferred Meats:</td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="Chiken Curry"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    preferredMeats: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.preferredMeats}
            />
          </td>
        </tr>
        <tr>
          <td>Water Intake Per Day*</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="5 Liter"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    waterIntakePerDay: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.waterIntakePerDay}
            />
          </td>
        </tr>
        <tr>
          <td>Do You Smoke?*</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-smoke_yes`}
              label={`Yes`}
              name="smoke"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    smoke: "yes",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.smoke === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-smoke_no`}
              label={`No`}
              name="smoke"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    smoke: "no",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.smoke === "no" ? true : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Do You Consume Alcohol*</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-consumeAlcoho_yes`}
              label={`Yes`}
              name="consumeAlcoho"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    consumeAlcoho: "yes",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.consumeAlcoho === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-consumeAlcoho_no`}
              label={`No`}
              name="consumeAlcoho"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    consumeAlcoho: "no",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.consumeAlcoho === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Do You Fast?</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-fast_yes`}
              label={`Yes`}
              name="fast"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    fast: "yes",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.fast === "yes" ? true : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-fast_no`}
              label={`No`}
              name="fast"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    fast: "no",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.fast === "no" ? true : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>How Often Do You Fast?</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="5 day"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    oftenYouFast: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.oftenYouFast}
            />
          </td>
        </tr>
        <tr>
          <td>Foods Consumed During Fast*</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="Food consumed"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    foodsConsumedDuringFas: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.foodsConsumedDuringFas}
            />
          </td>
        </tr>
        <tr>
          <td>How Often Do You Visit A Restaurant / Order In? *</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="Visit Restaurant"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    oftenVisitARestaurantOrOrderIn: e.target.value,
                  },
                })
              }
              value={
                stepSecondData.nutritionLifeStyle
                  ?.oftenVisitARestaurantOrOrderIn
              }
            />
          </td>
        </tr>
        <tr>
          <td>What Are Your Preferred Cuisines?</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="Preffered Cuisines"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    preferredCuisines: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.preferredCuisines}
            />
          </td>
        </tr>
        <tr>
          <td>Who Cooks at Home? *</td>
          <td colSpan={3}>
            <select
              name="cooksAtHome"
              className="form-control"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    cooksAtHome: e.target.value,
                  },
                })
              }
            >
              <option>Open this select menu</option>
              <option
                value="cook"
                selected={
                  stepSecondData.nutritionLifeStyle.cooksAtHome === "cook"
                    ? true
                    : false
                }
              >
                Cook
              </option>
              <option
                value="yourself"
                selected={
                  stepSecondData.nutritionLifeStyle.cooksAtHome === "yourself"
                    ? true
                    : false
                }
              >
                Yourself
              </option>
              <option
                value="wife"
                selected={
                  stepSecondData.nutritionLifeStyle.cooksAtHome === "wife"
                    ? true
                    : false
                }
              >
                Wife
              </option>
              <option
                value="mother"
                selected={
                  stepSecondData.nutritionLifeStyle.cooksAtHome === "mother"
                    ? true
                    : false
                }
              >
                Mother
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td>Are You A Quick / Slow Eater?*</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="Quick / Slow"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    quickOrSlowEater: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.quickOrSlowEater}
            />
          </td>
        </tr>
        <tr>
          <td>
            Hunger Peak Timing:
            <br />
            (Time of The Day When You Are Most Hungry)
          </td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="2 PM"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    hungerPeakTiming: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.hungerPeakTiming}
            />
          </td>
        </tr>
        <tr>
          <td>
            Do You Indulge in Any Other Activity While Eating? If Yes, Please
            Select*{" "}
          </td>
          <td colSpan={3}>
            <select
              name="indulgeInActivityWhileEating"
              className="form-control"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    indulgeInActivityWhileEating: e.target.value,
                  },
                })
              }
            >
              <option>Open this select menu</option>
              <option
                value="mobile"
                selected={
                  stepSecondData.nutritionLifeStyle
                    .indulgeInActivityWhileEating === "mobile"
                    ? true
                    : false
                }
              >
                Mobile
              </option>
              <option
                value="television"
                selected={
                  stepSecondData.nutritionLifeStyle
                    .indulgeInActivityWhileEating === "television"
                    ? true
                    : false
                }
              >
                Television
              </option>
              <option
                value="chat"
                selected={
                  stepSecondData.nutritionLifeStyle
                    .indulgeInActivityWhileEating === "chat"
                    ? true
                    : false
                }
              >
                Chat
              </option>
              <option
                value="newspaper"
                selected={
                  stepSecondData.nutritionLifeStyle
                    .indulgeInActivityWhileEating === "newspaper"
                    ? true
                    : false
                }
              >
                Newspaper
              </option>
              <option
                value="video_games"
                selected={
                  stepSecondData.nutritionLifeStyle
                    .indulgeInActivityWhileEating === "video_games"
                    ? true
                    : false
                }
              >
                Video games
              </option>
              <option
                value="work"
                selected={
                  stepSecondData.nutritionLifeStyle
                    .indulgeInActivityWhileEating === "work"
                    ? true
                    : false
                }
              >
                Work
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td>Any Food Aversions (Foods You Dislike) *</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="Food Name"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    foodsDislike: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.foodsDislike}
            />
          </td>
        </tr>
        <tr>
          <td>Any Food Preferences (Foods You Like) *</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="Food Name"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    foodsLike: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.foodsLike}
            />
          </td>
        </tr>
        <tr>
          <td>What Meals Do You Carry to Office? *</td>
          <td colSpan={3}>
            {mealsCarryToOfficeList.map((item) => (
              <>
                <Form.Control
                  type="checkbox"
                  checked={mealsCarryToOfficeListActive.includes(item)}
                  value={item}
                  onChange={(e) => {
                    onMealChange(e.target.value);
                    setStepSecondData({
                      ...stepSecondData,
                      nutritionLifeStyle: {
                        ...stepSecondData.nutritionLifeStyle,
                        mealsCarryToOffice: mealsCarryToOfficeListActive,
                      },
                    });
                  }}
                />{" "}
                {item}
              </>
            ))}
          </td>
        </tr>
        <tr>
          <td>Do You Work out/ Exercise? regularly*:</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-workoutExerciseRegularly_yes`}
              label={`Yes`}
              name="workoutExerciseRegularly"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    workoutExerciseRegularly: "yes",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.workoutExerciseRegularly ===
                "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-workoutExerciseRegularly_no`}
              label={`No`}
              name="workoutExerciseRegularly"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    workoutExerciseRegularly: "no",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.workoutExerciseRegularly ===
                "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Cardio Workout*:</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-cardioWorkout_yes`}
              label={`Yes`}
              name="cardioWorkout"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    cardioWorkout: "yes",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.cardioWorkout === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-cardioWorkout_no`}
              label={`No`}
              name="cardioWorkout"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    cardioWorkout: "no",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.cardioWorkout === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>What kind of Cardio Workout, Duration, Frequency, Timing?</td>
          <td colSpan={3}>
            <Form.Control
              plaintext
              placeholder="Cardio Exercise"
              onChange={(e) =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    kindOfCardioWorkout: e.target.value,
                  },
                })
              }
              value={stepSecondData.nutritionLifeStyle?.kindOfCardioWorkout}
            />
          </td>
        </tr>
        <tr>
          <td>Weight Training*</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-weightTraining_yes`}
              label={`Yes`}
              name="weightTraining"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    weightTraining: "yes",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.weightTraining === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-weightTraining_no`}
              label={`No`}
              name="weightTraining"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    weightTraining: "no",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.weightTraining === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Any Other Exercise*</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-anyOtherExercise_yes`}
              label={`Yes`}
              name="anyOtherExercise"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    anyOtherExercise: "yes",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.anyOtherExercise === "yes"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`anyOtherExercise_no`}
              label={`No`}
              name="anyOtherExercise"
              onChange={() =>
                setStepSecondData({
                  ...stepSecondData,
                  nutritionLifeStyle: {
                    ...stepSecondData.nutritionLifeStyle,
                    anyOtherExercise: "no",
                  },
                })
              }
              checked={
                stepSecondData.nutritionLifeStyle?.anyOtherExercise === "no"
                  ? true
                  : false
              }
            />
          </td>
        </tr>

        <tr>
          <td colSpan={4} className="text-right">
            <Button onClick={() => props.prev()} className="mr-5">
              Previous
            </Button>
            <Button onClick={saveStepTwo}>Next</Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default SecondStep;
