import React, { Component } from "react";
import axios from "axios";
import { Container, Row, Col, Tab, Nav, Button } from "react-bootstrap";

import PageBanner from "components/CommonComponents/PageBanner/PageBanner";

// icons : feather
import FeatherIcon from "feather-icons-react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
// import { assessmentForm } from 'utlis/apis/API_common'
import "./style/assesment.css";

// redux
import { connect } from "react-redux";
import {
  getUserAssesmentForm,
  postUserAssesmentForm,
} from "utlis/apis/API_common";

import AssesmentFormStep1 from "./includes/AssesmentFormStep1";
import AssesmentFormStep2 from "./includes/AssesmentFormStep2";
import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import ThirdStep from "./Steps/ThirdStep";
import FourStep from "./Steps/FourStep";
import * as FORM_KEYS from "./helpers/form_helpers";

const initialValues = {
  personalDETAILS: {
    name: "",
    email: "",
    mobile: " ",
    address: " ",
    dob: " ",
    gender: "Male",
    age: "17 (years)",
    weight: "44 (kg)",
    height: "6 (ft)",
    target: "200 (gm)",
    goal: "",
    caste: "",
    maritalStatus: "Unmarried",
    familyType: "Nuclear Family",
  },
  nutritionLifeStyle: {
    workStatus: "working",
    OccupationProfessio: "",
    designation: "",
    workTimings: "",
    jobIncludeFrequentTravel: "yes",
    lifestyl: "sedentary",
    deskJobOrNeededToSitForHours: "",
    carryMealsToOffice: "yes",
    eatingHabit: "",
    frequencyOfEatingEggs: "",
    frequencyOfEatingNonVeg: "",
    preferredMeats: "",
    waterIntakePerDay: "",
    smoke: "yes",
    consumeAlcoho: "yes",
    fast: "yes",
    oftenYouFast: "",
    foodsConsumedDuringFas: "",
    oftenVisitARestaurantOrOrderIn: "",
    preferredCuisines: "",
    cooksAtHome: "",
    quickOrSlowEater: "",
    hungerPeakTiming: "",
    indulgeInActivityWhileEating: "",
    foodsDislike: "",
    foodsLike: "",
    mealsCarryToOffice: "",
    workoutExerciseRegularly: "yes",
    cardioWorkout: "yes",
    kindOfCardioWorkout: "",
    weightTraining: "yes",
    anyOtherExercise: "yes",
  },
  medicalHistory: {
    haveAnyHealthIssues: "",
    anyKindOfSurgery: "",
    chronicFatigue: "",
    diabetes: "",
    difficultyDuringPhysicalExercise: "",
    fattyLiver: "",
    highBloodPressure: "",
    highProlactin: "",
    highUricAcidOrGout: "",
    historyOfBreathingOrLungAliments: "",
    hyperthyroidism: "",
    hypothyroidism: "",
    infertility: "",
    kidneyDisorder: "",
    lowBloodPressure: "",
    orthopedicAliment: "",
    stroke: "",
    anyOther: "",
    bowelOrDigestiveComplaints: "",
    anyProvenFoodAllergies: "",
    areYourPeriodsRegular: "",
    doYouPMS: "",
    lastMenstrualPeriods: "",
    areYouInMenopause: "",
    anyTestOrReportsDoneByYouRecently: "",
    areYouOnMedications: "",
    pregnancyHistory: "",
    numberOfChildren: "",
    anyPostPregnancyComplications: "",
    anyPostPregnancyComplicationsAnyOther: "",
    AreYouTakingAnySupplementsOrVitamins: "",
  },
  dietaryHabits: {
    pizzaBurger_amnt: "",
    biscuitsCookies_amnt: "",
    rotiChapati_amnt: "",
    parathas_amnt: "",
    chaat_amnt: "",
    sproutsPulses_amnt: "",
    rice_amnt: "",
    instantNoodles_amnt: "",
    breadPav_amnt: "",
    paneer_amnt: "",
    cheese_amnt: "",
    indianMithai_amnt: "",
    fruits_amnt: "",
    friedSnacks_amnt: "",
    nutsDryFruits_amnt: "",
    aeratedBeverages_amnt: "",
    preservedJuices_amnt: "",
    tea_amnt: "",
    coffee_amnt: "",
    healthDrinks_amnt: "",
    chocolate_amnt: "",
    ghee_amnt: "",
    butter_amnt: "",
    milk_amnt: "",
    curd_amnt: "",
    breakfastCereal_amnt: "",
    iceCream_amnt: "",
    frozenFood_amnt: "",
    seafood_amnt: "",
    fish_amnt: "",
    chicken_amnt: "",
    mutton_amnt: "",
    pork_amnt: "",
    eggs_amnt: "",
    midnightSnacks_amnt: "",
    cakesPastries_amnt: "",

    pizzaBurger_frqncy: "",
    biscuitsCookies_frqncy: "",
    rotiChapati_frqncy: "",
    parathas_frqncy: "",
    chaat_frqncy: "",
    sproutsPulses_frqncy: "",
    rice_frqncy: "",
    instantNoodles_frqncy: "",
    breadPav_frqncy: "",
    paneer_frqncy: "",
    cheese_frqncy: "",
    indianMitha_frqncy: "",
    fruits_frqncy: "",
    friedSnacks_frqncy: "",
    nutsDryFruits_frqncy: "",
    aeratedBeverages_frqncy: "",
    preservedJuices_frqncy: "",
    tea_frqncy: "",
    coffee_frqncy: "",
    healthDrinks_frqncy: "",
    chocolate_frqncy: "",
    ghee_frqncy: "",
    butter_frqncy: "",
    milk_frqncy: "",
    curd_frqncy: "",
    breakfastCereal_frqncy: "",
    iceCream_frqncy: "",
    frozenFood_frqncy: "",
    seafood_frqncy: "",
    fish_frqncy: "",
    chicken_frqncy: "",
    mutton_frqncy: "",
    pork_frqncy: "",
    eggs_frqncy: "",
    midnightSnacks_frqncy: "",
    cakesPastries_frqncy: "",
  },
};
class AssesmentForm extends Component {
  constructor(props) {
    super(props);

    // STATE
    this.state = {
      // 'submitBtnDisabled' : "false",
      // 'consultationBtnLoading' : "true",
      formData: initialValues,
      currentStep: 0,
    };

    // tabs refs
    this.step1Ref = React.createRef();
    this.step2Ref = React.createRef();

    this.changeHandler = this.changeHandler.bind(this);
    this.fieldChangeHandler = this.fieldChangeHandler.bind(this);

    this.nextStep = this.nextStep.bind(this);

    this.openStep1Tab = this.openStep1Tab.bind(this);
    this.openStep2Tab = this.openStep2Tab.bind(this);
    this.gettingDataFromStep1 = this.gettingDataFromStep1.bind(this);
    this.gettingDataFromStep2 = this.gettingDataFromStep2.bind(this);
    this.onAssessmentFormSubmit = this.onAssessmentFormSubmit.bind(this);
  }
  componentDidMount() {
  }

  onAssessmentFormSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    postUserAssesmentForm(this.props.commonToken, this.state.formData)
      .then((res) => {
        console.log(res);
      })
      .catch((er) => console.log(er));
  };

  nextStep = () => {
    this.setState({ ...this.state, currentStep: this.state.currentStep + 1 });
    console.log(this.state.currentStep);
  };
  prevStep = () => {
    this.setState({ ...this.state, currentStep: this.state.currentStep - 1 });
  };
  // opening step1 tab
  openStep1Tab = (ev) => {
    ev.preventDefault();
    this.step1Ref.click();
  };

  // opening step1 tab
  openStep2Tab = (ev) => {
    ev.preventDefault();
    this.step2Ref.click();
  };

  // getting data from form step 1
  gettingDataFromStep1(userData) {
    if (userData) {
      this.setState(
        {
          step1Data: { ...userData },
        },
        () => {}
      );
    }
  }

  // getting data from form step 1
  gettingDataFromStep2(userData) {
    if (userData) {
      this.setState(
        {
          step2Data: { ...userData },
        },
        () => {}
      );
    }
  }

  changeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };

  fieldChangeHandler = (e) => {
    const target = e.target;
    const name = target.name;
    this.setState((prevState) => ({
      formData: {
        ...this.state.formData,
        [name]: e.target.value,
      },
    }));
    console.log(e.target.value);
  };


  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Nutrivalve Wellness | Assessment Form</title>
          <link rel="canonical" href="" />
          <meta name="description" content="Assessment Form" />
        </Helmet>
        {/* header */}
        {/* <Header /> */}

        <section id="st-wrapper">
          {/* page banner */}
          <PageBanner
            pageTitle="Assessment Form"
            lastLinkName="Assessment Form"
            lastLinkPath="/assessment-form"
          />

          <Container>
            <Row className="mt-5 mb-5">
              <Col
                md={3}
                className={this.state.currentStep === 0 && "active-tab"}
              >
                PERSONAL DETAILS
              </Col>
              <Col
                md={3}
                className={this.state.currentStep === 1 && "active-tab"}
              >
                NUTRITION & LIFESTYLE ANALYSIS
              </Col>
              <Col
                md={3}
                className={this.state.currentStep === 2 && "active-tab"}
              >
                MEDICAL HISTORY{" "}
              </Col>
              <Col
                md={3}
                className={this.state.currentStep === 3 && "active-tab"}
              >
                DIETARY HABITS
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  {this.state.currentStep === 0 && (
                    <FirstStep
                      data={this.state.formData}
                      commonToken = {this.props.commonToken}
                      next={this.nextStep}
                    />
                  )}
                  {this.state.currentStep === 1 && (
                    <SecondStep data={this.state.formData}
                    commonToken = {this.props.commonToken}
                    next={this.nextStep}
                    prev={this.prevStep}  />
                  )}
                  {this.state.currentStep === 2 && (
                    <ThirdStep data={this.state.formData}
                    commonToken = {this.props.commonToken}
                    next={this.nextStep}
                    prev={this.prevStep}  />
                  )}
                  {this.state.currentStep === 3 && (
                    <FourStep data={this.state.formData}
                    commonToken = {this.props.commonToken}
                    prev={this.prevStep} />
                  )}
                </Row>
              </Col>
              <Col md={12}>
                <Row>
                  {/* <Button
                    onClick={() => this.prevStep()}
                    disabled={this.state.currentStep === 0 ? true : false}
                  >
                    Previous
                  </Button>
                  {this.state.currentStep !== 3 && (
                    <Button
                      onClick={() => this.nextStep()}
                      disabled={this.state.currentStep === 3 ? true : false}
                    >
                      Next
                    </Button>
                  )}
                  {this.state.currentStep === 3 && (
                    <Button onClick={(e) => this.onAssessmentFormSubmit(e)}>
                      Save
                    </Button>
                  )} */}
                </Row>
              </Col>
            </Row>
            <Row className="contact-us-sec-2">
              <Col
                md={12}
                className="st-heading-wrapper mb-3 mt-3 mb-lg-5"
              ></Col>
            </Row>
          </Container>

          {/* footer */}
          {/* <Footer /> */}
        </section>
      </>
    );
  }
}

const getDataFromStore = (state) => {
  return {
    commonToken: state.auth.commonToken,
    user: state.auth.currentUser,
  };
};

export default connect(getDataFromStore, null)(AssesmentForm);
