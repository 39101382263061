export const STEPS_FORM_VALUE_KEY_PERSONAL_DETAILS = 'personalDetails';

export const FORM_KEY_NAME = 'name';
export const FORM_KEY_EMAIL = 'email';
export const FORM_KEY_MOBILE = 'mobile';
export const FORM_KEY_ADDRESS = 'address';
export const FORM_KEY_DOB = 'dob';
export const FORM_KEY_GENDER = 'gender';
export const FORM_KEY_AGE = 'age';
export const FORM_KEY_WEIGHT = 'weight';
export const FORM_KEY_HEIGHT = 'height';
export const FORM_KEY_TARGET = 'target';
export const FORM_KEY_GOAL = 'goal';
export const FORM_KEY_CASTE = 'caste';
export const FORM_KEY_MARITAL_STATUS = 'maritalStatus';
export const FORM_KEY_FAMILYTYPE = 'familyType';