import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { getUserAssesmentForm, postUserAssesmentForm } from "utlis/apis/API_common";

function FirstStep(props) {
  const [stepOneData, setStepOneData] = useState(props.data);
  useEffect(() => {
    getUserAssesmentForm(props.commonToken)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data && data.assesmentData) {
          console.log(data.assesmentData[0]);
          
          setStepOneData({
            ...data.assesmentData[0]
          })
        }
      })
      .catch((er) => console.log(er));
  },[]);

  const saveStepOne = () => {
    postUserAssesmentForm(props.commonToken, stepOneData)
      .then((res) => {
      props.next();
        console.log(res);
      })
      .catch((er) => console.log(er));

      
  };
  
  return (
    <Table striped bordered hover responsive>
      <tbody>
        <tr>
          <td>Name*:</td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="Name"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    name: e.target.value,
                  },
                })
              }
              value={stepOneData.personalDETAILS?.name}
            />
          </td>
        </tr>
        <tr>
          <td>Email Address*: </td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="email@example.com"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    email: e.target.value,
                  },
                })
              }
              value={stepOneData.personalDETAILS?.email}
            />
          </td>
        </tr>
        <tr>
          <td>Mobile Number*:</td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="Mobile Number"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    mobile: e.target.value,
                  },
                })
              }
              value={stepOneData.personalDETAILS?.mobile}
            />
          </td>
        </tr>
        <tr>
          <td>Address:</td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="Address"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    address: e.target.value,
                  },
                })
              }
              value={stepOneData.personalDETAILS?.address}
            />
          </td>
        </tr>
        <tr>
          <td>Date of Birth*:</td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="Date of Birth"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    dob: e.target.value,
                  },
                })
              }
              value={stepOneData.personalDETAILS?.dob}
            />
          </td>
        </tr>
        <tr>
          <td>Gender*</td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-male`}
              label={`Male`}
              name="gender"
              onChange={() =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    gender: "Male",
                  },
                })
              }
              checked={
                stepOneData.personalDETAILS?.gender === "Male" ? true : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-female`}
              label={`Female`}
              name="gender"
              onChange={() =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    gender: "Female",
                  },
                })
              }
              checked={
                stepOneData.personalDETAILS?.gender === "Female" ? true : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Age*</td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="Age"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    age: e.target.value,
                  },
                })
              }
              value={stepOneData.personalDETAILS?.age}
            />
          </td>
        </tr>
        <tr>
          <td>Weight*</td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="Weight"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    weight: e.target.value,
                  },
                })
              }
              value={stepOneData.personalDETAILS?.weight}
            />
          </td>
        </tr>
        <tr>
          <td>Height*: (in cm)</td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="Height"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    height: e.target.value,
                  },
                })
              }
              value={stepOneData.personalDETAILS?.height}
            />
          </td>
        </tr>
        <tr>
          <td>What’s Your Target Weight?</td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="Target Weight?"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    target: e.target.value,
                  },
                })
              }
              value={stepOneData.personalDETAILS?.target}
            />
          </td>
        </tr>
        <tr>
          <td>Any Other Goal That You Have in Mind? *</td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="Goal That You Have in Mind?"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    goal: e.target.value,
                  },
                })
              }
              value={stepOneData.personalDETAILS?.goal}
            />
          </td>
        </tr>
        <tr>
          <td>Caste*:</td>
          <td colSpan={4}>
            <Form.Control
              plaintext
              placeholder="Caste"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    caste: e.target.value,
                  },
                })
              }
              value={stepOneData.personalDETAILS?.caste}
            />
          </td>
        </tr>
        <tr>
          <td>Marital Status*</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-m`}
              label={`Married `}
              name="maritalStatus"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    maritalStatus: "Married",
                  },
                })
              }
              checked={
                stepOneData.personalDETAILS?.maritalStatus === "Married"
                  ? true
                  : false
              }
            />
          </td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-u`}
              label={`Unmarried`}
              name="maritalStatus"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    maritalStatus: "Unmarried",
                  },
                })
              }
              checked={
                stepOneData.personalDETAILS?.maritalStatus === "Unmarried"
                  ? true
                  : false
              }
            />
          </td>
          <td colSpan={2}>
            <Form.Check
              type={"radio"}
              id={`default-s`}
              label={`Separated`}
              name="maritalStatus"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    maritalStatus: "Separated",
                  },
                })
              }
              checked={
                stepOneData.personalDETAILS?.maritalStatus === "Separated"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td>Type of Family</td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-jf`}
              label={`Joint Family`}
              name="familyType"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    familyType: "Joint Family",
                  },
                })
              }
              checked={
                stepOneData.personalDETAILS?.familyType === "Joint Family"
                  ? true
                  : false
              }
            />
          </td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-nf`}
              label={`Nuclear Family`}
              name="familyType"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    familyType: "Nuclear Family",
                  },
                })
              }
              checked={
                stepOneData.personalDETAILS?.familyType === "Nuclear Family"
                  ? true
                  : false
              }
            />
          </td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-pg`}
              label={`Paying Guest`}
              name="familyType"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    familyType: "Paying Guest",
                  },
                })
              }
              checked={
                stepOneData.personalDETAILS?.familyType === "Paying Guest"
                  ? true
                  : false
              }
            />
          </td>
          <td>
            <Form.Check
              type={"radio"}
              id={`default-along`}
              label={`Alone`}
              name="familyType"
              onChange={(e) =>
                setStepOneData({
                  ...stepOneData,
                  personalDETAILS: {
                    ...stepOneData.personalDETAILS,
                    familyType: "Alone",
                  },
                })
              }
              checked={
                stepOneData.personalDETAILS?.familyType === "Alone"
                  ? true
                  : false
              }
            />
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="text-right">
            <Button onClick={saveStepOne}>Next</Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default FirstStep;
